<template>
  <div>
    <div v-if="isMobile === false">
      <el-dialog
        v-if="languageSelect === 'zh'"
        title="温馨提示"
        :modal-append-to-body="false"
        :visible.sync="dialogVisibleInfo"
        width="480px"
        :before-close="handleInfoClose"
        top="40vh"
      >
        <span>完善个人信息，即可查看更多详情内容</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleInfoClose"> 取消 </el-button>
          <el-button type="primary" @click="wanshan()"> 去完善 </el-button>
        </span>
      </el-dialog>
      <el-dialog
        v-if="languageSelect === 'en'"
        title="Warm reminder"
        :modal-append-to-body="false"
        :visible.sync="dialogVisibleInfo"
        width="480px"
        :before-close="handleInfoClose"
        top="40vh"
      >
        <span>Improve your personal information to see more details</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleInfoClose"> Cancel </el-button>
          <el-button type="primary" @click="wanshan()">To perfect</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-else class="phone">
      <el-dialog
        v-if="languageSelect === 'zh'"
        title="温馨提示"
        :modal-append-to-body="false"
        :visible.sync="dialogVisibleInfo"
        width="75%"
        :before-close="handleInfoClose"
        top="35vh"
      >
        <span>完善个人信息，即可查看更多详情内容</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleInfoClose"> 取消 </el-button>
          <el-button type="primary" @click="wanshan()">去完善</el-button>
        </span>
      </el-dialog>
      <el-dialog
        v-if="languageSelect === 'en'"
        title="Warm reminder"
        :modal-append-to-body="false"
        :visible.sync="dialogVisibleInfo"
        width="75%"
        :before-close="handleInfoClose"
        top="35vh"
      >
        <span>Improve your personal information to see more details</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleInfoClose"> Cancel </el-button>
          <el-button type="primary" @click="wanshan()">To perfect</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dialogVisibleInfo: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isMobile: false,
      languageSelect: "",
    };
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
  },
  methods: {
    handleInfoClose() {
      this.$emit("hideDialog");
    },
    wanshan() {
      this.handleInfoClose();
      if (this.isMobile === false) {
        this.$router.push({
          path: "/Info",
        });
      } else {
        this.$router.push({
          path: "/Info1",
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.phone {
  position: fixed;
  z-index: 9999999999;

  ::v-deep {
    .el-dialog {
      margin: auto !important;
      font-size: 14px;

      top: 35vh;
    }
    .el-dialog__header {
      padding: 5px;
      border-bottom: 1px solid #ebebeb;
      .el-dialog__title {
        font-weight: bold;
        padding: 15px;
        font-size: 18px;
      }
    }
    .el-dialog__body {
      padding: 10px 15px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
    .el-button {
      width: 105px;
      height: 28px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      border: 1px solid var(--main-color);
      padding: 0;
      a {
        display: block;
      }
    }
  }
}

::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 0;
    cursor: pointer;
    a {
      display: block;
    }
  }
  .el-button--primary {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: #fff;
  }
  .el-button:focus,
  .el-button:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    background-color: #fff;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);
  }
}
</style>
