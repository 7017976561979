/*
 * @Author: gyy
 * @Date: 2023-11-30 22:03:29
 * @LastEditors: 自由命名
 * @LastEditTime: 2023-12-22 18:26:50
 * @FilePath: \zixun-vue\src\utils\vxshare.js
 * @filePurpose: 
 */
import {
  shares
} from "@/api/index";
import wx from "weixin-js-sdk";
// 封装分享方法
export function shareUrl(shareData) {
  shares(shareData.url).then(res => {
    const config = res.data;
    setTimeout(() => {
      // console.log(config, 'config')
      wx.config({
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: [
          'onMenuShareAppMessage',
          'onMenuShareTimeline',
          'updateAppMessageShareData', // 分享到朋友
          'updateTimelineShareData', // 分享到朋友圈
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ],
        success: function () {
          // alert('1')
          console.log(111)
        },

        fail: function (res) {
          console.log(22, res)
          // alert(JSON.stringify(res))
        }
      });
      wx.ready(() => {
        var shareData1 = {
          title: shareData.title,
          desc: shareData.text,
          link: shareData.url, // 分享后的地址
          imgUrl: 'https://www.oilgasinfoai.com/profile/upload/2023/12/22/wxShare_20231222182549A002.png',
          success: function () {
            // alert('22222222222')
          },
          fail: function (res) {
            console.log(res)
            // alert('111111111111')
            // alert(JSON.stringify(res))
          }
        };
        wx.onMenuShareTimeline(shareData1);
        wx.updateAppMessageShareData(shareData1)
        wx.onMenuShareAppMessage(shareData1)
        wx.updateTimelineShareData(shareData1);
      })
    }, 200)

  });
}