<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.fullPath" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view :key="$route.fullPath" v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "./utils/ql.css";
@media screen and (max-width: 757px) {
  ::v-deep .el-notification {
    /* 在这里放置在小于757px宽度时需要更改的通知框宽度 */
    width: 250px !important; /* 更改通知框的宽度 */
  }
}

#app {
  font-family: arial, sans-serif, "Microsoft Yahei";
  // text-align: center;
  color: #2c3e50;
  font-size: 16px;
  // background: #f7f7f7;
  min-height: 100vh;
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
.centerWidth {
  // width: 1280px;
  width: 1160px;
  margin: 0 auto;
}

@media (max-width: 1300px) {
  .centerWidth {
    width: 95%;
  }
}
:root {
  // --main-color: #bd0303;
  --main-bg-color: #bf0022;
  --main-color: #1a79ff;
  --grey: #f8f8f8;
  --white: #fff;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
div,
p {
  box-sizing: border-box;
}
.mainContent {
  margin-bottom: 50px;
}

html,
body {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
    SimSun, sans-serif !important;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
}
.header {
  flex: 0;
}
.mainContent {
  flex: 1;
}

.pwsRed {
  color: var(--main-color);
  width: 100%;
  text-align: left !important;
  margin-top: 10px !important;
  font-size: 13px;
  margin: 0 auto;
  /* min-height: 17px; */
}
#footer {
  flex: 0;
}
.el-input__inner {
  border: none !important;
  border-radius: 0;
}
.el-input__inner:hover {
  border: none;
}
.gery .el-select {
  width: 100%;
  background: #f5f5f7 !important;
  border-radius: 5px;
}
.el-date-table td.current:not(.disabled) span {
  background: var(--main-color) !important;
  color: #fff !important;
}
.el-date-table td.today span,
.el-date-table td.available:hover {
  color: var(--main-color) !important;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #bf0022 !important;
  color: #fff !important;
}
.el-select-dropdown__item.selected {
  background: #bf0022 !important;
  color: #fff !important;
}
.gery .el-form-item__content {
  width: 400px;
}
.gery .el-input__inner {
  background: #f5f5f7 !important;
  border-radius: 5px;
}
.gery .el-input__inner::placeholder {
  color: #666666;
}
.noData,
.notLogin {
  text-align: center;
  color: #666666;
  font-size: 18px;
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 100px 0px;
  img {
    width: 306px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  a,
  div,
  span {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-top: 14px;
    width: 108px;
    height: 32px;
    border-radius: 16px;
    line-height: 32px;
    text-align: center;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    font-size: 14px;
  }
}
.noData a:hover {
  background: var(--main-color);
  color: #fff;
}

.notLogin {
  a:hover,
  div:hover,
  span:hover {
    background: var(--main-color);
    color: #fff;
  }
}

.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  cursor: pointer;
  /* 超出显示省略号 */
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // white-space: normal;
}

.twoLine {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.threeLine {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.amplify {
  transition: transform 0.5s ease-out;
}
.amplify:hover {
  transform: scale(1.1);
}
.el-main {
  padding: 0 !important;
}

// 面包屑导航
.el-breadcrumb {
  padding-bottom: 24px;
  padding-top: 30px;
}
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link,
.el-breadcrumb__inner span {
  color: #666666 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
// 分页选中
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: var(--main-color) !important; //修改默认的背景色
}
// 分页鼠标经过
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: var(--main-color) !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff !important;
}
// 角标
.el-badge__content,
.el-progress.is-exception .el-progress-bar__inner {
  background-color: var(--main-color) !important;
  border: none !important;
}

.content-box{
  width: 830px;
}



</style>
