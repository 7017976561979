<template>
  <el-footer height="auto" id="footer">
    <div class="foot centerWidth">
      <div class="flex">
        <p><a href="https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=2957254971134827&webPageTitle=%E5%9C%A8%E7%BA%BF%E5%92%A8%E8%AF%A2">{{ $t('header.service') }}</a></p>
        <span> | </span>
        <a href="/Feedback">{{ $t('header.feedback') }}</a>
        <span> | </span>
        <p class="red">
          <a @click="toTop" href="javascript:void(0);">{{ $t('common.backtop') }}</a>
        </p>
      </div>
      <div class="flex">
        <img
          src="@/assets/beian.png"
          alt=""
          class="boderG"
        />
        <p class="red1">京ICP备2022034763号-6</p>
      </div>
    </div>
  </el-footer>
</template>
<script>
import { columnNav, columnContact, columnQrcode } from "@/api/index";
export default {
  data() {
    return {
      languageSelect: "",
      baseURL: this.$utils.baseURL,
      foot_navs: [],
      contentType: {
        phone: "",
        email: "",
        address: "",
      },
      qrcode: [],
    };
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    // this.GetColumnNav();
    // this.GetColumnContact();
    // this.GetColumnQrcode();
  },
  methods: {
    toTop(){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },

    GetColumnNav() {
      columnNav()
        .then((res) => {
          this.foot_navs = res.data;
        })
        .catch(() => {});
    },
    GetColumnContact() {
      columnContact()
        .then((res) => {
          this.contentType = res.data;
        })
        .catch(() => {});
    },
    GetColumnQrcode() {
      columnQrcode()
        .then((res) => {
          this.qrcode = res.data;
        })
        .catch(() => {});
    },
    link(url) {
      if (url) window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
#footer {
  bottom: 0;
  width: 100%;
}
.el-footer {
  background: #f5f9ff;
  color: #999999;
  .footer {
    padding-top: 60px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    .left {
      flex: 1;
      width: 0;
      // display: grid;
      // grid-template-columns: auto auto auto 320px;
      column-gap: 70px;
      text-align: left;
      font-size: 12px;
      display: flex;
      justify-content: flex-start;
      .item {
        .title {
          display: block;
          color: #fff;
          font-size: 18px;
          margin-bottom: 12px;
          cursor: pointer;
        }
        .list {
          div,
          a {
            display: block;
            margin-bottom: 14px;
          }
          .link {
            cursor: pointer;
          }
        }
      }
      .content {
        width: 400px;
      }
    }
    .right {
      margin-left: 70px;
      display: flex;
      div {
        width: 110px;
        font-size: 14px;
        color: #999999;
        text-align: center;
        img {
          width: 110px;
          height: 110px;
          margin-bottom: 10px;
        }
      }
      div:last-child {
        margin-left: 40px;
      }
    }
  }
  .foot {
    text-align: center;
    padding: 10px 0px 10px 0px;
    // border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; 
      &:first-child{
        border-bottom: 1px solid #e6ebf2;
      }
    }
    

    p, a {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 12px;
    }

    p, a{
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 12px;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }
}

.boderG {
  width: 17px;
  height: 17px;
  padding: 0 5px;
}

.red1 {
  color: #999999;
  font-size: 12px;
  line-height: 20px;
}

.red1 a {
  color: #999999;
}
</style>
