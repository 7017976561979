/*
 * @Author: 自由命名
 * @Date: 2023-12-20 22:19:28
 * @LastEditors: 自由命名
 * @LastEditTime: 2024-01-13 09:25:29
 * @FilePath: \zixun-vue\src\main.js
 * @filePurpose:
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import utils from './utils/config'
import md5 from 'js-md5'
import dataV from '@jiaminghi/data-view'
import {
  Pagination
} from 'element-ui';
import i18n from './lang'
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import VueCookies from 'vue-cookies';
// 只在开发环境下初始化 VConsole
import {
  getUser,
  // insertUserAgent
} from "@/api/user";

if (localStorage.getItem('language') === 'en') {
  locale.use(lang);
  Vue.use(Pagination);
}


// Vue.use(Pagination, {
//   i18n: (key, value) => i18n.t(key, value)
// });

Vue.config.productionTip = false
Vue.use(dataV)
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.prototype.$axios = axios
Vue.prototype.$utils = utils;
import common from './utils/common'

Vue.use(common);
Vue.use(VueCookies);

// console.log(Vue.$cookies.get('language'))
// console.log(VueCookies)

Vue.prototype.$md5 = md5;
Vue.filter('ellipsis', function (value) {
  if (value.length > 86) {
    return value.slice(0, 86) + "..."
  } else {
    return value
  }
})
router.beforeEach((to, from, next) => {
  console.log('进入开始')
  console.log(to, store)

  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

  to.meta.isMobile = !!flag
  // console.log(to.query.code, store.state.access_token)

  //需要权限
  if (to.query.code && !store.state.access_token) {
    let path = to.fullPath.split("?code")[0]
    if (to.query.language || to.query.id) {
      console.log('有id')
      path = to.fullPath.split("&code")[0]
    }
    let data = {
      code: to.query.code,
      url: utils.redirectURL + path,
    };
    console.log(data, 'data', to)
    getUser(data)
      .then((res) => {
        res.data.token && localStorage.setItem("access_token", res.data.token);
        res.data.access_token && localStorage.setItem("Zyaccess_token", res.data.access_token);
        res.data.idass && localStorage.setItem("idass", res.data.idass);
        res.data.type && localStorage.setItem("typeInfo", res.data.type);
        store.state.typeInfo = res.data.type;
        store.state.access_token = res.data.token;
        store.state.idass = res.data.idass
   
        let data = {
          userAgent: localStorage.getItem('murmur'),
          idass: store.state.idass,
        };
        // insertUserAgent(data)
        // https://www.oilgasinfoai.com
        const insertUserAgent = Vue.prototype.$axios;
        insertUserAgent.post("/auth/oauth/insertUserAgent", data)
          .then((res) => {
            console.log(res)
            next({
              path: path,
            });
          })
        // window.location.replace(path);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  console.log('往下进行')
  if (localStorage.getItem("myclick")) {
    localStorage.removeItem("myclick")
  }
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }

  localStorage.setItem('Title', '')
  // const storedLanguage =  localStorage.getItem("language");

  // next()
  // // 首页没有语言
  // if (to.name === 'Home' && !to.query.language) {
  //   console.log('首页没有语言')
  //   const langParam = {
  //     language: storedLanguage || 'zh'
  //   };
  //   console.log('111111')
  //   next({
  //     path: to.path,
  //     query: langParam
  //   });
  // }
  // // 首页有语言
  // else if (to.name === 'Home' &&to.query.language) {
  //   console.log('有语言')
  //   next();
  //   // if (to.path === "/user") {
  //   //   console.log('22222')
  //   //   next();
  //   // } else {
  //   //   if (to.meta.requireAuth) {
  //   //     //判断当前是否拥有权限
  //   //     if (store.state.access_token) {
  //   //       console.log('33333')
  //   //       next();
  //   //     } else {
  //   //       // 无权，跳转登录
  //   //       console.log('44444')
  //   //       next({
  //   //         path: "/",
  //   //       });
  //   //     }
  //   //   } else {
  //   //     console.log('55555')
  //   //     // 不需要权限，直接访问
  //   //     next();
  //   //   }
  //   // }
  // }
  // // 不是首页
  // else {
  //   console.log('没有语言')
  //   const langParam = {
  //     language: storedLanguage || 'zh',
  //     ...to.query
  //   };
  //   next({
  //     path: to.path,
  //     query: langParam
  //   });
  //   // if (to.path === "/user") {
  //   //   console.log('66666')
  //   //   next({
  //   //     path: to.path,
  //   //     query: langParam
  //   //   });
  //   // } else {
  //   //   if (to.meta.requireAuth) {
  //   //     //判断当前是否拥有权限
  //   //     if (store.state.access_token) {
  //   //       console.log('77777')
  //   //       next({
  //   //         path: to.path,
  //   //         query: langParam
  //   //       });
  //   //     } else {
  //   //       // 无权，跳转登录
  //   //       // next({
  //   //       //   path: "/",
  //   //       // });
  //
  //   //       console.log('8888')
  //   //       next({
  //   //         path: "/",
  //   //         query: langParam
  //   //       });
  //   //     }
  //   //   } else {
  //   //     console.log('9999')
  //   //     // 不需要权限，直接访问
  //   //     next({
  //   //       path: to.path,
  //   //       query: langParam
  //   //     });
  //   //   }
  //   // }
  // }
  // 英文
  // if (storedLanguage === 'en') {
  //   document.title = "Oil and Gas knowledge-" + to.name
  // }
  // // 中文
  // else {
  //   document.title = "油气知识-" + to.meta.nameEn;
  // }

  document.title = (to.meta.title ? i18n.t(to.meta.title) : (i18n.t('common.webName') + '-' + i18n.locale == 'en' ? to.name : to.meta.nameEn));
  if(to.meta.title){
    document.querySelector('meta[property="og:title"]').content = i18n.t(to.meta.title)
  }else{
    document.querySelector('meta[property="og:title"]').content = i18n.t('common.webName')
  }

  if(to.meta.keywords){
    document.querySelector('meta[property="og:keywords"]').content = i18n.t(to.meta.keywords)
  }else{
    document.querySelector('meta[property="og:keywords"]').content = i18n.t('common.defaultKeywords')
  }

  if(to.meta.description){
    document.querySelector('meta[property="og:description"]').content = i18n.t(to.meta.description)
  }else{
    document.querySelector('meta[property="og:description"]').content = i18n.t('common.defaultdDscription')
  }

  // 存在分类ID
  // if (to.query.id) {
  //   const axiosInstance = Vue.prototype.$axios;
  //   axiosInstance.get("/api/category/optionSelect", {
  //       params: {
  //         level: 1,
  //         categoryId: to.query.id,
  //       },
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: localStorage.getItem("access_token"),
  //       },
  //     })
  //     .then((res) => {
  //       if (storedLanguage === 'en') {
  //         document.title = "Oil and Gas knowledge-" + res.data.data[0].titleEn
  //       } else {
  //         document.title = "油气知识-" + res.data.data[0].title;
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       if (storedLanguage === 'en') {
  //         document.title = "Oil and Gas knowledge-" + 'Reports'
  //       } else {
  //         document.title = "油气知识-" + '行业报告';
  //       }
  //     });

  // }

  if (to.path === "/user") {
    next();
  } else {
    if (to.meta.requireAuth) {
      //判断当前是否拥有权限
      if (store.state.access_token) {
        next();
      } else {
        // 无权，跳转登录
        next({
          path: "/",
        });
      }
    } else {
      // 不需要权限，直接访问
      next();
    }
  }

});




Vue.use(ElementUI)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')