<template>
  <div>
    <div v-if="isMobile === false">
      <el-dialog
        v-if="languageSelect === 'zh'"
        title="温馨提示"
        :visible.sync="dialogVisible"
        width="480px"
        :before-close="handleClose"
        top="40vh"
      >
        <span>欢迎使用油气知识，注册登录后体验完整服务~</span>
        <span slot="footer" class="dialog-footer">
          <el-button>
            <div style="cursor: pointer" @click="login">登录</div>
          </el-button>
          <el-button type="primary">
            <div style="cursor: pointer" @click="login">注册</div>
          </el-button>
        </span>
      </el-dialog>
      <el-dialog
        v-if="languageSelect === 'en'"
        title="Warm reminder"
        :visible.sync="dialogVisible"
        width="480px"
        :before-close="handleClose"
        top="40vh"
      >
        <span
          >Welcome to use Oil & Gas Information, sign in to experience the full service~</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button>
            <div style="cursor: pointer" @click="login">Log in</div>
          </el-button>
          <el-button type="primary">
            <div style="cursor: pointer" @click="login">Sign in</div>
          </el-button>
        </span>
      </el-dialog>
    </div>
    <div v-if="isMobile === true" style="font-size: 14px" class="phone">
      <el-dialog
        v-if="languageSelect === 'zh'"
        title="温馨提示"
        :visible.sync="dialogVisible"
        width="75%"
        :before-close="handleClose"
        top="35vh"
      >
        <span>欢迎使用油气知识，注册登录后体验完整服务~</span>
        <span slot="footer" class="dialog-footer">
          <el-button>
            <div style="cursor: pointer" @click="login">登录</div>
          </el-button>
          <el-button type="primary">
            <div style="cursor: pointer" @click="login">注册</div>
          </el-button>
        </span>
      </el-dialog>
      <el-dialog
        v-if="languageSelect === 'en'"
        title="Warm reminder"
        :visible.sync="dialogVisible"
        width="75%"
        :before-close="handleClose"
        top="35vh"
      >
        <span
          >Welcome to use Oil & Gas Information, sign in to experience the full service~</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button>
            <div style="cursor: pointer" @click="login">Log in</div>
          </el-button>
          <el-button type="primary">
            <div style="cursor: pointer" @click="login">Sign in</div>
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { getAuthorize } from "@/api/user";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isMobile: false,
      languageSelect: "",
    };
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
  },
  methods: {
    handleClose() {
      this.$emit("hideDialog");
    },
    // 登录
    login() {
      // let href = "https://www.oilgasinfoai.com/?language=zh";
      let href = window.location.href;
      getAuthorize(href).then((res) => {
        console.log(res, "res");
        window.location.assign(res.data.url);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.phone {
  position: fixed;
  z-index: 9999999999;

  ::v-deep {
    .el-dialog {
      margin: auto !important;
      font-size: 14px;

      top: 35vh;
    }
    .el-dialog__header {
      padding: 5px;
      border-bottom: 1px solid #ebebeb;
      .el-dialog__title {
        font-weight: bold;
        padding: 15px;
        font-size: 18px;
      }
    }
    .el-dialog__body {
      padding: 10px 15px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
    .el-button {
      width: 105px;
      height: 28px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      border: 1px solid var(--main-color);
      padding: 0;
      a {
        display: block;
      }
    }
  }
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 0;
    a {
      display: block;
    }
  }
  .el-button--primary {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: #fff;
  }
  .el-button:focus,
  .el-button:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    background-color: #fff;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
  }
  @media (max-width: 767px) {
    .el-dialog__headerbtn{
      top: 10px;
    }
  }
  
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);
  }
}
</style>
