var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"MySubscriptions wrapper"},[_c('Header'),(_vm.isMobile === false)?_c('div',[_c('el-main',{staticClass:"centerWidth mainContent"},[_c('div',{staticClass:"hotBox"},[_c('div',{staticClass:"banner"},[(_vm.recommendList[0])?_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.recommendList),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"image",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('img',{attrs:{"src":item[_vm.trans('img,imgEn')]
                      ? _vm.baseURL + item[_vm.trans('img,imgEn')]
                      : '',"alt":""}})]),_c('div',{staticClass:"desc"},[_c('p',{on:{"click":function($event){$event.stopPropagation();return _vm.toDetail(item)}}},[_vm._v(" "+_vm._s(item[_vm.trans("title,titleEn")])+" ")])])])}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2):_vm._e()],1),_c('div',{staticClass:"recommend"},[_c('div',[_c('ul',{staticClass:"bannerBox"},_vm._l((_vm.ads),function(item){return _c('li',{key:item.id},[_c('a',{attrs:{"href":item[_vm.trans('url,urlEn')],"target":"_blank"}},[_c('img',{staticClass:"bannerPhoto",attrs:{"src":item[_vm.trans('img,imgEn')]
                        ? _vm.baseURL + item[_vm.trans('img,imgEn')]
                        : ''}}),_c('div',{staticClass:"bannerT"},[_c('p',{staticClass:"twoLine"},[_vm._v(" "+_vm._s(item[_vm.trans("name,titleEn")])+" ")])])])])}),0)])])]),(_vm.tableList.status != 1)?_c('TableList',{attrs:{"showMore":true,"tableList":_vm.tableList},on:{"change":_vm.showDialog}}):_vm._e(),_c('AiPop',{on:{"change":_vm.showDialog}})],1)],1):_c('div',[_c('el-main',{staticClass:"centerWidth1 mainContent1"},[_c('div',{staticClass:"hotBox1"},[_c('div',{staticClass:"banner"},[(_vm.recommendList[0])?_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.recommendList),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"image",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('img',{attrs:{"src":item[_vm.trans('img,imgEn')]
                        ? _vm.baseURL + item[_vm.trans('img,imgEn')]
                        : '',"alt":""}})]),(_vm.languageSelect === 'zh')?_c('div',{staticClass:"desc"},[_c('p',{on:{"click":function($event){$event.stopPropagation();return _vm.toDetail(item)}}},[_vm._v(_vm._s(item.title))])]):_vm._e(),(_vm.languageSelect === 'en')?_c('div',{staticClass:"desc"},[_c('p',{on:{"click":function($event){$event.stopPropagation();return _vm.toDetail(item)}}},[_vm._v(_vm._s(item.titleEn))])]):_vm._e()])}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2):_vm._e()],1)]),(_vm.tableList.status != 1)?_c('TableList',{staticClass:"marginTop1",attrs:{"showMore":true,"tableList":_vm.tableList},on:{"change":_vm.showDialog}}):_vm._e(),_c('ScrollTop'),_c('AiPop',{on:{"change":_vm.showDialog}})],1)],1),(_vm.isMobile === false)?_c('Footer'):_vm._e(),(_vm.isMobile === true)?_c('Bottom',[_vm._v("123")]):_vm._e(),_c('LoginTip',{attrs:{"dialogVisible":_vm.dialogVisible},on:{"hideDialog":_vm.hideDialog}}),_c('InfoTip',{attrs:{"dialogVisibleInfo":_vm.dialogVisibleInfo},on:{"hideDialog":_vm.hideDialogInfo}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }