/*
 * @Author: 自由命名
 * @Date: 2023-11-21 14:04:00
 * @LastEditors: 自由命名
 * @LastEditTime: 2024-01-06 16:22:42
 * @FilePath: \zixun-vue\src\store\index.js
 * @filePurpose: 
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_token: localStorage.getItem('access_token') || '',
    user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
    idass: localStorage.getItem('idass') || '',
    statistic: '',
    userId: localStorage.getItem('userId') || '',
    typeInfo: localStorage.getItem('typeInfo') || '',
    MessageCount: 0,
    navList: [],
    language: localStorage.getItem('language') || 'zh',
  },
  getter: {
    language: state => state.language
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language
      localStorage.setItem('language', language)
    },
    SET_NAVLIST: (state, data) => {
      state.navList = data
    }
  },
  actions: {
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
    setNavList({ commit }, data) {
      commit('SET_NAVLIST', data)
    }
  },
  modules: {}
})