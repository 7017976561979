/*
 * @Author: 自由命名
 * @Date: 2023-11-30 19:22:11
 * @LastEditors: 自由命名
 * @LastEditTime: 2024-01-11 16:54:01
 * @FilePath: \zixun-vue\src\utils\config.js
 * @filePurpose:
 */
export default {

  // 接口服务地址+端口
  // baseURL: 'http://localhost:18050',
  baseURL: `${location.origin}`,
  // baseURL: 'https://112.126.100.212:18050', //正式环境ip
  // baseURL: 'https://www.oilgasinfoai.com',
  // baseURL: 'http://110.40.254.239:18050'

  // 竹云单点登录 回调地址
  redirectURL: `${location.origin}`,
}