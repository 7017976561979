import request from '@/utils/request'
// 获取用户信息   /thirdPlatform/home/user/info
export function userInfo() {
  return request({
    url: '/thirdPlatform/home/user/info',
    method: 'get',
  })
}

// 获取手机验证码 /api/user/phoneCode/{phone}
export function phoneCode(data) {
  return request({
    url: `/api/user/phoneCode`,
    method: 'post',
    data: data,
  })
}

// 获取邮箱验证码 /api/user/emailCode/{phone}
export function emailCode(data) {
  return request({
    url: `/api/user/emailCode`,
    method: 'get',
    params: data,
  })
}


// 获取协议 /api/agreement/detail/{id}   1  隐私协议   2   注册协议
export function agreement(id) {
  return request({
    url: `/api/agreement/detail/${id}`,
    method: 'get',
  })
}

// 手机注册 /api/user/phoneRegister
export function phoneRegister(data) {
  return request({
    url: `/api/user/phoneRegister`,
    method: 'post',
    data: data,
  })
}

// 邮箱注册 /api/user/emailRegister
export function emailRegister(data) {
  return request({
    url: `/api/user/emailRegister`,
    method: 'post',
    data: data,
  })
}

// 账号登录  /api/user/login
export function userLogin(data) {
  return request({
    url: `/api/user/login      `,
    method: 'post',
    data: data,
  })
}

// 短信登录  /api/user/phoneLogin
export function phoneLogin(data) {
  return request({
    url: `/api/user/phoneLogin`,
    method: 'post',
    data: data,
  })
}

// 忘记密码  /api/user/forgetPassword
export function forgetPassword(data) {
  return request({
    url: `/api/user/forgetPassword`,
    method: 'post',
    data: data,
  })
}
// 退出登录  /auth/oauth/logOut
export function logOut(data) {
  return request({
    url: `/auth/oauth/logOut`,
    method: 'get',
    params: {
      redirect_uri: data
    }
  })
}

// 获取用户详细信息  /api/user/detail
export function userDetail() {
  return request({
    url: `/api/user/detail`,
    method: 'get',
  })
}

// 修改用户基本信息  /api/user/update
export function userUpdate(data) {
  return request({
    url: `/api/user/update`,
    method: 'post',
    data: data,
  })
}

// 上传单个文件  /common/upload
export function UploadData(data) {
  return request({
    url: `/common/upload`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data,
  })
}


// 修改邮箱 /api/user/upEmail
export function upEmail(data) {
  return request({
    url: `/api/user/upEmail`,
    method: 'post',
    data: data,
  })
}

// 修改手机号 /api/user/upPhone
export function upPhone(data) {
  return request({
    url: `/api/user/upPhone`,
    method: 'post',
    data: data,
  })
}

// 修改密码 /api/user/upPassword
export function upPassword(data) {
  return request({
    url: `/api/user/upPassword`,
    method: 'post',
    data: data,
  })
}

// 获取我的订阅新闻订阅  /api/subscribe/getNews
export function getNews(data) {
  return request({
    url: `/api/subscribe/getNews`,
    method: 'get',
    params: data,
  })
}

// 获取我的订阅行业报告  /api/subscribe/getReport
export function getReport(data) {
  return request({
    url: `/api/subscribe/getReport`,
    method: 'get',
    params: data,
  })
}

// 取消我的订阅  /api/subscribe/delete/{id}
export function deleteReport(id) {
  return request({
    url: `/api/subscribe/delete/${id}`,
    method: 'get',
  })
}

// 获取系统消息条数  /api/notice/count/{userId}
export function noticeCount(userId) {
  return request({
    url: `/api/notice/count/${userId}`,
    method: 'get',
  })
}

// 获取系统消息列表  /api/notice/list/{userId}
export function noticeList(data) {
  return request({
    url: `/api/notice/list`,
    method: 'get',
    params: data
  })
}

// 获取系统通知详情   /api/notice/detail/{noticeId}
export function noticeDetail(id) {
  return request({
    url: `/api/notice/detail/${id}`,
    method: 'get',
  })
}

// 修改通知公告已读状态   /api/notice/upStatus/{id}
export function noticeUpStatus(id) {
  return request({
    url: `/api/notice/upStatus/${id}`,
    method: 'get',
  })
}


// 获取微信code  /api/user/getWxCode
export function getWxCode(data) {
  return request({
    url: `/api/user/getWxCode`,
    method: 'get',
    params: {
      redirect_uri: data
    }
  })
}


// 判断用户是否绑定 /api/user/isBind
export function isBind(data) {
  return request({
    url: `/api/user/isBind`,
    method: 'POST',
    data: {
      wxCode: data
    }
  })
}

// 微信登录  /api/user/wxLogin
export function wxLogin(data) {
  return request({
    url: `/api/user/wxLogin`,
    method: 'POST',
    data: data
  })
}

// 微信绑定  /api/user/wxBind
export function wxBind(data) {
  return request({
    url: `/api/user/wxBind`,
    method: 'get',
    params: {
      code: data
    }
  })
}
// 微信解绑  /api/user/unBind

export function unBind(data) {
  return request({
    url: `/api/user/unBind`,
    method: 'POST',
    data: data
  })
}


// 付费会员套餐列表 /api/member/list
export function memberlist() {
  return request({
    url: `/api/member/list`,
    method: 'get'
  })
}

// 订单生成返回微信二维码   /api/order/qrCode  post payId	套餐id type	支付类型1pc端，2移动端
export function orderqrCode(data) {
  return request({
    url: `/api/order/qrCode`,
    method: 'POST',
    data: data
  })
}


// 支付宝支付  /api/order/aliCode payId	套餐id type	支付类型1pc端，2移动端
export function orderaliCode(data) {
  return request({
    url: `/api/order/aliCode`,
    method: 'POST',
    data: data
  })
}

//  payPal订单生成， /api/order/payPalOrder
export function payPalOrder(data) {
  return request({
    url: `/api/order/payPalOrder`,
    method: 'POST',
    data: data
  })
}

// 根据订单号查询订单  /api/order/getOrderBycode/{orderCode}
export function getOrderBycode(id) {
  return request({
    url: `/api/order/getOrderBycode/${id}`,
    method: 'get',
  })
}

// 获取订单列表  /api/order/getOrder
export function getOrder(data) {
  return request({
    url: `/api/order/getOrder`,
    method: 'GET',
    params: data
  })
}


// 获取微信支付code  api/order/getCode
export function getWxPayCode(data) {
  return request({
    url: `/api/order/getCode`,
    method: 'get',
    params: {
      redirect_uri: data
    }
  })
}

// 登录获取授权码url /auth/oauth/getAuthorize
export function getAuthorize(data) {
  return request({
    url: `/auth/oauth/getAuthorize`,
    method: 'get',
    params: {
      redirect_uri: data
    }
  })
}

// 获取用户信息  /auth/oauth/getUser
export function getUser(data) {
  return request({
    url: `/auth/oauth/getUser`,
    method: 'get',
    params: {
      code: data.code,
      redirect_uri: data.url
    }
  })
}

// 检测token有效性  /auth/oauth/introspect
export function introspect(data) {
  return request({
    url: `/auth/oauth/introspect`,
    method: 'get',
    params: {
      access_token: data
    }
  })
}


//根据浏览器唯一标识获取浏览器用户登录状态 /auth/oauth/getLoginStatus
export function getLoginStatus(data) {
  return request({
    url: `/auth/oauth/getLoginStatus`,
    method: 'post',
    data: data
  })
}

//用户登录插入浏览器唯一标识 /auth/oauth/insertUserAgent
export function insertUserAgent(data) {
  return request({
    url: `/auth/oauth/insertUserAgent`,
    method: 'post',
    data: data
  })
}

// 删除唯一标识  /auth/oauth/deleteUserAgent
export function deleteUserAgent(data) {
  return request({
    url: `/auth/oauth/deleteUserAgent`,
    method: 'post',
    data: {
      userAgent: data
    }
  })
}