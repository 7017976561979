module.exports = {
    data(){
        return {
            baseURL: this.$utils.baseURL,
        }
    },
    computed: {
        // val 规定为,分割的字符串，元素顺序规定为中文字段，英文字段
        trans(){
            const map = {
            'zh': 0,
            'en': 1
            }
            return val => {
                let valArray = val.split(',');
                return valArray[map[this.$i18n.locale]]
            }
        }
    }
}