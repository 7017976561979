import axios from "axios"; //原生的axios
import {
	// Loading,
	Message
} from 'element-ui'
// import utils from './config'
//用来拦截用的
import store from '../store/index'
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
// const loading = { //loading加载对象
//     loadingInstance: null,
//     //打开加载
//     open() {
//         if (this.loadingInstance === null) { // 如果实例 为空，则创建
//             this.loadingInstance = Loading.service({
//                 text: '加载中...', //加载图标下的文字
//                 spinner: 'el-icon-loading', //加载图标
//                 background: 'rgba(0, 0, 0, 0.8)', //背景色
//                 customClass: 'loading' //自定义样式的类名
//             })
//         }
//     },
//     //关闭加载
//     close() {
//         // 不为空时, 则关闭加载窗口
//         if (this.loadingInstance !== null) {
//             this.loadingInstance.close()
//         }
//         this.loadingInstance = null
//     }
// }
//创建一个单例


const http = axios.create({

	// baseURL: utils.baseURL, //域名

	timeout: 1200000, //响应时间
	credentials: 'include',
	headers: {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Credentials": true,
		"Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept'

	},

})

//拦截器  -请求拦截

http.interceptors.request.use(config => {

	//部分接口需要token

	let token = store.state.access_token;

	if (token) {

		config.headers.Authorization = 'Bearer ' + token;

		// config.headers ={

		// 'token':token

		// }

	}
	// loading.open() //打开加载窗口
	return config;

}, err => {

	return Promise.reject(err)

})

//拦截器  -响应拦截

http.interceptors.response.use(res => {
	// loading.clone() //关闭加载窗口

	if (res.data.code === 200) {

		return Promise.resolve(res.data)

		//这里读者们可以根据服务器返回的数据去自行修改

	} else if ((res.data.code === 401)) {
		localStorage.removeItem("userInfo");
		localStorage.removeItem("access_token");
		localStorage.removeItem("userId");
		localStorage.removeItem("typeInfo");
		store.state.access_token = "";
		store.state.user = {};
		return Promise.reject(res.data)
	} else {
		Message({
			message: res.data.msg,
			type: 'error',
			duration: 5000
		})
		if ((res.data.msg == '该用户已被删除！') || (res.data.msg == '该用户已停用，请联系管理员。')) {
			localStorage.removeItem("userInfo");
			localStorage.removeItem("access_token");
			localStorage.removeItem("userId");
			localStorage.removeItem("typeInfo");
			store.state.access_token = "";
			store.state.user = {};
		}
		return Promise.reject(res.data)

	}

}, err => {
	if (err.message.includes('timeout')) {
		Message({
			message: '网络连接超时，请稍后再试！',
			type: 'error',
			duration: 5000
		})
		return Promise.reject('timeout'); // reject这个错误信息
		// 判断请求异常信息中是否含有超时timeout字符串
	}
	return Promise.reject(err)

});

//整体导出

export default http;