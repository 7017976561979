<template>
  <div class="MySubscriptions wrapper">
    <Header></Header>
    <div v-if="isMobile === false">
      <el-main class="centerWidth mainContent">
        <div class="hotBox">
          <div class="banner">
            <swiper
              :options="swiperOption"
              ref="mySwiper"
              v-if="recommendList[0]"
            >
              <swiper-slide v-for="(item, index) in recommendList" :key="index">
                <div class="image" @click="toDetail(item)">
                  <img
                    :src="
                      item[trans('img,imgEn')]
                        ? baseURL + item[trans('img,imgEn')]
                        : ''
                    "
                    alt=""
                  />
                </div>
                <div class="desc">
                  <p @click.stop="toDetail(item)">
                    {{ item[trans("title,titleEn")] }}
                  </p>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
          <div class="recommend">
            <!-- <div
              class="item"
              v-for="(item, index) in recommendList"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="oneLine">
                {{ item.title }}
              </div>
            </div> -->
            <div>
              <ul class="bannerBox">
                <li v-for="item in ads" :key="item.id">
                  <a :href="item[trans('url,urlEn')]" target="_blank">
                    <img
                      :src="
                        item[trans('img,imgEn')]
                          ? baseURL + item[trans('img,imgEn')]
                          : ''
                      "
                      class="bannerPhoto"
                    />
                    <div class="bannerT">
                      <p class="twoLine">
                        {{ item[trans("name,titleEn")] }}
                      </p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <TableList
          v-if="tableList.status != 1"
          :showMore="true"
          :tableList="tableList"
          @change="showDialog"
        ></TableList>
        <!-- <div class="reBox">
          <div class="scrollTop" @click="backTop" v-if="scrollType">返回顶部</div>
        </div> -->

        <!-- <PDFList
          class="marginTop"
          :showMore="true"
          :PDFList="pdfList"
          :showCancel="false"
          @change="showDialog"
        ></PDFList>-->

        <AiPop @change="showDialog"></AiPop>
      </el-main>
    </div>
    <div v-else>
      <el-main class="centerWidth1 mainContent1">
        <div class="hotBox1">
          <div class="banner">
            <swiper
              :options="swiperOption"
              ref="mySwiper"
              v-if="recommendList[0]"
            >
              <swiper-slide v-for="(item, index) in recommendList" :key="index">
                <div class="image" @click="toDetail(item)">
                  <img :src="item[trans('img,imgEn')]
                          ? baseURL + item[trans('img,imgEn')]
                          : ''" alt="" />
                </div>
                <div class="desc" v-if="languageSelect === 'zh'">
                  <p @click.stop="toDetail(item)">{{ item.title }}</p>
                </div>
                <div class="desc" v-if="languageSelect === 'en'">
                  <p @click.stop="toDetail(item)">{{ item.titleEn }}</p>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
        <TableList
          v-if="tableList.status != 1"
          class="marginTop1"
          :showMore="true"
          :tableList="tableList"
          @change="showDialog"
        ></TableList>
        <!-- <PDFList
          class="marginTop1"
          :showMore="true"
          :PDFList="pdfList"
          :showCancel="false"
          @change="showDialog"
        ></PDFList> -->
        <!-- <div class="flexBox1">
          <NewList
            v-for="(item, index) in categoryRecommendList"
            :key="index"
            :newList.sync="item"
            :showCancel="false"
            @change="showDialog"
          ></NewList>
          <NewList :newList="newList2"></NewList>
        </div> -->
        <!-- <AiPop @change="showDialog"></AiPop> -->
        <ScrollTop></ScrollTop>
        <AiPop @change="showDialog"></AiPop>
      </el-main>
    </div>

    <Footer v-if="isMobile === false"></Footer>
    <Bottom v-if="isMobile === true">123</Bottom>
    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
    <InfoTip
      :dialogVisibleInfo="dialogVisibleInfo"
      @hideDialog="hideDialogInfo"
    ></InfoTip>
  </div>
</template>
<script>
import {
  recommend,
  categoryRecommend,
  businessList,
  reportList,
  getAds,
} from "@/api/index";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import LoginTip from "@/components/LoginTip.vue";
// import NewList from "@/components/NewList.vue";
import TableList from "@/components/table.vue";
// import PDFList from "@/components/PDFList.vue";
import AiPop from "@/components/AiPop.vue";
import InfoTip from "@/components/InfoTip.vue";
import { shareUrl } from "@/utils/vxshare.js";
import mixins from "@/utils/mixins.js";
import ScrollTop from "@/components/mobile/ScrollTop.vue"
import Bottom from "@/components/mobile/Bottom.vue";

export default {
  name: "Home",
  components: {
    ScrollTop,
    Bottom,
    Header,
    Footer,
    // NewList,
    TableList,
    // PDFList,
    swiper,
    swiperSlide,
    AiPop,
    LoginTip,
    InfoTip,
  },
  mixins: [mixins],
  data() {
    return {
      isMobile: false,
      languageSelect: "",
      swiperOption: {
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: true,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
          // dynamicBullets: true,
          // dynamicMainBullets: 4,
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      recommendList: [],
      categoryRecommendList: [],
      tableList: {
        title: "商机洞察",
        titleEn: "Business",
        url: "/Science?id=3",
        list: [],
        status: null,
        isWhole: null,
      },
      pdfList: {
        title: "行业报告",
        titleEn: "Reports",
        url: "/Science?id=2",
        list: [],
      },
      dialogVisible: false,
      dialogVisibleInfo: false,
      titleEn: "",

      
      ads: [],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  created() {
    this.isMobile = this.$route.meta.isMobile
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932-->
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    this.recommend();
    // this.categoryRecommend();
    // this.businessList();
    // this.reportList();

    this.getAds();

    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    async recommend() {
      let res = await recommend();
      this.recommendList = res.data;
    },
    categoryRecommend() {
      categoryRecommend().then((res) => {
        this.categoryRecommendList = res.data;
      });
    },
    businessList() {
      console.log("11111");
      businessList().then((res) => {
        console.log(res);
        this.tableList.list = res.data.data;
        this.tableList.isWhole = res.data.isWhole;
        this.tableList.status = res.data.status;
        businessList().then(async (res) => {
          this.tableList.list = res.data.data;
          // 遍历tableList中的每个项目
          for (const item of this.tableList.list) {
            var vm = this;
            await vm.$axios
              .post("/api/enes/trans", {
                newsId: item.id,
                headers: {
                  Authorization: vm.token,
                },
              })
              .then((res) => {
                vm.titleEn = res.data.data.enTitle;
                vm.contentEn = res.data.data.enContent;
                this.$set(this, "titleEn", vm.titleEn);
              })
              .catch((error) => {
                console.log(error);
              });
            item.titleEn = this.titleEn;
            // 假设setText和内容翻译函数在其他地方定义
            if (item.content) item.contentText = this.setText(item.content);
            if (this.languageSelect === "en") {
              item.title = this.setText(item.titleEn);
              if (item.secondaryName === "中文商机") {
                item.secondaryName = this.setText("Chinese Business");
              }
              if (item.secondaryName === "英文商机") {
                item.secondaryName = this.setText("English Business");
              }
            }
          }
        });
      });
    },

    reportList() {
      reportList().then((res) => {
        this.pdfList.list = res.rows;
      });
    },
    toDetail(item) {
      // eslint-disable-next-line no-empty
      // if (!this.$store.state.access_token && item.isView != "1") {
      //   this.showDialog(true);
      //   return false;
      // }
      // if (this.$store.state.typeInfo == 0 && item.isWhole != "1") {
      //   let that = this;
      //   that.showDialogInfo(true);
      //   return false;
      // }
      // this.$router.push(item.url);
      window.location.href = item[this.trans('url,urlEn')];
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showDialogInfo(type) {
      this.dialogVisibleInfo = type;
    },
    hideDialogInfo() {
      this.dialogVisibleInfo = false;
    },
    async getAds() {
      let res = await getAds({
        positions: "1,2,3,4",
      });
      this.ads = res.data;
    },
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    // this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
};
</script>
<style lang="scss" scoped>
.centerWidth1 {
  width: calc(100% - 32px);
  margin: 0 auto;
}
.mainContent1 {
  flex: 1;
}

.centerWidth {
  width: 1160px !important;
  margin: 0 auto;
  overflow: hidden;
}
.hotBox1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  height: 58%;
  .banner {
    flex: 1;
    height: 188px;
    margin: 0 auto;
    min-width: 95%;
    .swiper-container {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .swiper-container .swiper-slide {
      width: 100%;
      position: relative;
      .image {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .desc {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        font-size: 16px;
        padding: 0 5px;
        height: 28px;
        line-height: 28px;
        position: absolute;
        left: 0;
        bottom: 0;
        p {
          width: 80%;
          cursor: pointer;
        }
      }
    }
    .swiper-pagination {
      height: 28px;
      line-height: 28px;
      bottom: 0;
      right: 8px;
      left: auto;
      width: fit-content;
    }
  }
  .recommend {
    width: 98%;
    background: #fff;
    margin-left: 10px;
    .item {
      width: 100%;
      cursor: pointer;
      padding: 5px 10px;
      font-size: 14px;
      color: #333333;
    }
    .item:hover {
      background: var(--main-color);
      color: #fff;
    }
    .item:not(:last-child) {
      border-bottom: 1px dotted #cecece;
    }
  }
}
.hotBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  .banner {
    flex: 1;
    height: 320px;
    min-width: 830px;
    .swiper-container {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .swiper-container .swiper-slide {
      width: 100%;
      position: relative;
      .image {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .desc {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        font-size: 18px;
        padding: 0 20px;
        height: 52px;
        line-height: 52px;
        position: absolute;
        left: 0;
        bottom: 0;
        p {
          width: 80%;
          cursor: pointer;
        }
      }
    }
    .swiper-pagination {
      height: 52px;
      line-height: 52px;
      bottom: 0;
      right: 18px;
      left: auto;
      width: fit-content;
    }
  }
  @media (max-width: 1300px) {
    .banner {
      flex: 1;
      height: 360px;
      min-width: 830px;
      .swiper-container {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .swiper-container .swiper-slide {
        width: 100%;
        position: relative;
        .image {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
        .desc {
          width: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          color: #ffffff;
          font-size: 18px;
          padding: 0 20px;
          height: 52px;
          line-height: 52px;
          position: absolute;
          left: 0;
          bottom: 0;
          p {
            width: 80%;
            cursor: pointer;
          }
        }
      }
      .swiper-pagination {
        height: 52px;
        line-height: 52px;
        bottom: 0;
        right: 18px;
        left: auto;
        width: fit-content;
      }
    }
  }
  .recommend {
    width: 330px;
    background: #fff;
    margin-left: 10px;
    .item {
      width: 100%;
      cursor: pointer;
      padding: 0px 20px;
      font-size: 16px;
      color: #333333;
      height: 60px;
      line-height: 60rpx;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item:hover {
      background: var(--main-color);
      color: #fff;
    }
    .item:not(:last-child) {
      border-bottom: 1px dotted #cecece;
    }
  }
}
.flexBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
}
.flexBox1 {
  display: block;
  margin-top: 10px;
}
.marginTop {
  margin-top: 30px;
}
.marginTop1 {
  margin-top: 10px;
}
</style>
<style>
.swiper-container .swiper-pagination-bullet {
  background: #c2c2c2 !important;
  opacity: 1;
  width: 6px;
  height: 6px;
  border-radius: 3px;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background: rgba(0, 0, 0, 0.25) url(../assets/left.png) no-repeat center
    center;
  width: 26px;
  height: 38px;
  border-radius: 0px 5px 5px 0px;
  left: 0;
  background-size: 9px auto;
}
/* .swiper-button-prev:hover, .swiper-container-rtl .swiper-button-next:hover {
  background-image: url(../assets/leftHover.png);;
} */
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background: rgba(0, 0, 0, 0.25) url(../assets/right.png) no-repeat center
    center;
  width: 26px;
  height: 38px;
  right: 0;
  border-radius: 5px 0px 0px 5px;
  background-size: 9px auto;
}
/* .swiper-button-next:hover, .swiper-container-rtl .swiper-button-prev:hover {
  background-image: url(../assets/rightHover.png);
} */
.swiper-button-prev:hover,
.swiper-container-rtl .swiper-button-next:hover,
.swiper-button-next:hover,
.swiper-container-rtl .swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.swiper-container .swiper-pagination-bullet-active {
  width: 18px;
  height: 6px;
  border-radius: 3px;
  background: var(--main-color) !important;
}

.tecP1 {
  width: 580px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #262626;
  transition: color 0.3s;
  padding-top: 2px;
  &:hover{
    color: var(--main-color);
  }
}

.tecP2 {
  margin-top: 10px;
  height: 44px;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  color: #8a9099;
  text-overflow: ellipsis;
}

.reBox {
  position: relative;
}
.scrollTop {
  width: 35px;
  height: 35px;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  bottom: 65px;
  right: 20px;
  z-index: 1002;
  border: 1px solid #000;
}


</style>

