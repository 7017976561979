<template>
  <div>
    <div v-if="isMobile === false" class="Insight">
      <div class="allTitle">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
          class="content-box"
        >
          <el-tab-pane :label="$t('index.technology')" name="first">
            <div>
              <div
                v-for="item in mainTechs"
                :key="item.id"
                style="display: flex; margin-top: 10px; margin-bottom: 10px"
              >
                <div>
                  <a :href="`/technology/${item.id}`">
                    <img :src="item[trans('img,imgEn')] ? baseURL + item[trans('img,imgEn')] : ''" alt="" style="width: 210px; height: 140px" />
                  </a>
                </div>
                <div style="padding-left: 20px">
                  <p class="tecP1">
                    <a :href="`/technology/${item.id}`">{{ item[trans('title,titleEn')] }}</a>
                  </p>
                  <p class="tecP2 twoLine" v-html="item[trans('content,contentEn')]"></p>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      line-height: 20px;
                      margin-top: 20px;
                    ">
                    <div>
                      <span class="label-box" v-for="(label, labelIndex) in (item[trans('label,labelEn')] ? item[trans('label,labelEn')].split(',') : [])"
                        :key="labelIndex">{{label}}</span>
                    </div>
                    <p
                      style="font-size: 12px; line-height: 20px; color: #a1a8b3"
                    >
                      {{ item.createTime }}
                    </p>
                  </div>
                </div>
              </div>
              <div style="height: 50px; text-align: center; margin-top: 50px">
                <router-link to="/technology">
                  <el-button type="primary" plain>{{
                    $t("index.more")
                  }}</el-button>
                </router-link>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('index.news')" name="second">
            <div>
              <div
                v-for="item in mainNews"
                :key="item.id"
                style="display: flex; margin-top: 10px; margin-bottom: 10px"
              >
                <div>
                  <a :href="`/news/${item.id}`">
                    <img :src="item[trans('img,imgEn')] ? baseURL + item[trans('img,imgEn')] : ''" alt="" style="width: 210px; height: 140px" />
                  </a>
                </div>
                <div style="padding-left: 20px">
                  <p class="tecP1">
                    <a :href="`/news/${item.id}`">{{ item[trans('title,titleEn')] }}</a>
                  </p>
                  <p class="tecP2 twoLine" v-html="item[trans('content,contentEn')]"></p>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      line-height: 20px;
                      margin-top: 20px;
                    ">
                    <div>
                      <span class="label-box" v-for="(label, labelIndex) in (item[trans('label,labelEn')] ? item[trans('label,labelEn')].split(',') : [])"
                        :key="labelIndex">{{label}}</span>
                    </div>
                    <p
                      style="font-size: 12px; line-height: 20px; color: #a1a8b3"
                    >
                      {{ item.createTime }}
                    </p>
                  </div>
                </div>
              </div>

              <div style="height: 50px; text-align: center; margin-top: 50px">
                <router-link to="/news">
                  <el-button type="primary" plain>{{
                    $t("index.more")
                  }}</el-button>
                </router-link>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>

        <Right :modules="rightModules"></Right>
      </div>
    
      <InfoTip
        :dialogVisibleInfo="dialogVisibleInfo"
        @hideDialog="hideDialog"
      ></InfoTip>
    </div>
    <div v-if="isMobile === true" class="Insight1">
      <div class="centerWidth1 nav1">
        <router-link
          v-for="(item, index) in navList"
          :key="index"
          :to="item.url || ''"
          @click="setActive(index)"
          :class="[
            activeName == item.url ? 'active' : '',
            'item',
            item.url == '/Science?id=3' ? 'after' : '',
            item.url == '/mySubscriptions' ? 'after' : '',
          ]"
        >
          <div class="navBox">
            <img
              class="navBg"
              :src="item.icon" />
            <div>
              <p>{{ item[trans('name,nameEn')] }}</p>
            </div>
          </div>
        </router-link>
      </div>
      <MRight :modules="mRightModules"></MRight>
      
      <InfoTip
        :dialogVisibleInfo="dialogVisibleInfo"
        @hideDialog="hideDialog"
      ></InfoTip>
    </div>
  </div>
</template>
<script>
import { getNews, navList, getNewsEn } from "@/api/index";
import InfoTip from "@/components/InfoTip.vue";
import Right from "@/components/Right.vue";
import mixins from "@/utils/mixins.js";
import MRight from "@/components/mobile/Right.vue";
import { mapState } from "vuex";

export default {
  name: "tableList",
  mixins: [mixins],
  components: {
    InfoTip,
    Right,
    MRight
  },
  data() {
    return {
      // navList: this.$store.state.navList,
      isMobile: false,
      languageSelect: "",
      dialogVisibleInfo: false,
      

      activeName: "first",
      mainTechs: [],
      mainNews: [],
      rightModules: [
        {
          type: 'opportunity',
          path: 'opportunity',
          title: this.$t('right.latestBusiness'),
          hasMoreBtn: true,
          dataNum: 10,
          primaryTypeId: 3
        },
        {
          type: 'report',
          path: 'report',
          title: this.$t('right.latestReport'),
          dataNum: 5,
          // primaryTypeId: 2
        },
        {
          type: 'news',
          path: 'news',
          title: this.$t('right.latestInfo'),
          dataNum: 5,
          primaryTypeId: 4
        }
      ],
      navList: [],
      mRightModules: [
        {
          type: 'opportunity',
          path: 'opportunity',
          title: this.$t('index.opportunity'),
          hasMoreBtn: true,
          dataNum: 5,
          primaryTypeId: 3
        },
        {
          type: 'report',
          path: 'report',
          title: this.$t('index.report'),
          dataNum: 4,
          // primaryTypeId: 2
        },
        {
          type: 'news',
          path: 'technology',
          hasNavTab: true,
          navs: [
            {
              label: this.$t('index.technology'),
              type: 'technology',
              primaryTypeId: 1,
            },
            {
              label: this.$t('index.news'),
              type: 'news',
              primaryTypeId: 4,
            },
          ],
          hasMoreBtn: true,
          dataNum: 20,
          primaryTypeId: 1
        }
      ],
    };
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // val 规定为,分割的字符串，元素顺序规定为中文字段，英文字段
    trans() {
      const map = {
        zh: 0,
        en: 1,
      };
      return (val) => {
        let valArray = val.split(",");
        return valArray[map[this.$i18n.locale]];
      };
    },
    ...mapState({
      access_token: "access_token",
      userInfo: "user",
      navs: 'navList'
    }),
  },
  created() {
    console.log("crgth");
    console.log(this.tableList);
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }

    this.navList = this.navs.filter(item => {
      return !('2,7'.includes(item.bannerId))
    })

    // 科技前沿
    this.getNewsHandle(1);
    // 新闻动态
    this.getNewsHandle(4);
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.navList.length || this.getNavList();

    this.userId = localStorage.getItem("userId");
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    // this.activeName = this.$route.path;
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  methods: {
    getNavList() {
      const map = {
        4: 'technology',
        8: 'opportunity',
        3: 'news',
        5: 'report'
      }
      navList().then((res) => {
        this.navList = res.data.filter(item => {
          return !('2,7'.includes(item.bannerId))
        }).map(item => {
          item.icon = require(`@/assets/ico_nav_${map[item.bannerId]}.png`)
          return item
        })
      });
    },

    setActive(index) {
      this.activeIndex = index;
      // 确保导航数据已加载
      this.$nextTick(() => {
        // 获取导航项的父容器
        const navWrapper = document.querySelector(".nav1");
        if (navWrapper) {
          // 获取被点击项的位置（假设每个导航项宽度相同）
          const itemWidth = navWrapper.children[0].offsetWidth;

          // 将被点击项移动到中心位置
          navWrapper.scrollTo({
            left: itemWidth * index - (navWrapper.offsetWidth - itemWidth) / 2,
            behavior: "smooth",
          });
        }
      });
    },
    async getNewsHandle(id) {
      let fn = this.$i18n.locale == 'en' ? getNewsEn : getNews;
      let res = await fn({
        primaryTypeid: id,
        pageNum: 1,
        pageSize: 12,
        draftStatus: 0,
        articleFlag: 1,
        isSort: 2,
      });
       this[id == 1 ? "mainTechs" : "mainNews"] = res.rows.map(item => {
        item.content = this.setText(item.content || '').substr(0, 150)
        item.contentEn = this.setText(item.contentEn || '').substr(0, 150)

        return item
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    rowClass() {
      return "background-color: #FEF5F5; color: #333333;font-size: 18px;";
    },
    rowClass1() {
      return "background-color: #FEF5F5; color: #333333;font-size: 14px;overflow-x:hidden";
    },
    //时间格式化
    dateFormat(row) {
      let time = new Date(row.createTime); // row 表示一行数据, createdTime 表示要格式化的字段名称
      if (!time) {
        return "";
      }
      let year = time.getFullYear();
      let month = this.dateIfAddZero(time.getMonth() + 1);
      let day = this.dateIfAddZero(time.getDate());
      return year + "-" + month + "-" + day + " ";
    },
    dateIfAddZero(time) {
      return time < 10 ? "0" + time : time;
    },
    handleRowClick(row) {
      // 在这里处理行点击事件
      if (!this.$store.state.access_token) {
        let that = this;
        that.$emit("change", true);
        return false;
      }
      if (this.$store.state.typeInfo == 0 && this.tableList.isWhole != "1") {
        let that = this;
        that.showDialog(true);
        return false;
      }
      // this.$router.push({
      //   name: "Commercial", //注意使用 params 时一定不能使用 path
      //   params: {
      //     fanyititle: row.title,
      //     content: row.content,
      //     date: row.createTime,
      //     id: row.secondaryTypeid,
      //   },
      // });
      this.$router.push({
        path: "/articalDetail",
        query: {
          Typeid: row.primaryTypeid,
          newId: row.id,
        },
      });
    },
    showDialog(type) {
      this.dialogVisibleInfo = type;
    },
    hideDialog() {
      this.dialogVisibleInfo = false;
    },
  },
};
</script>
<style lang="scss" scoped>
// .el-button--primary.is-plain {
//   background: #fff;
// }

.el-timeline-item__content {
  height: 44px;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 0 20px 0;
}
</style>
<style lang="scss" scoped>
.Insight {
  background: #fff;
  // background: url(../assets/bg1.png) no-repeat right bottom, #fff;
  // background-size: 376px 262px;
  padding: 30px 0px;
  .allTitle {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // border-bottom: 1px solid #cecece;
    // margin: 0 20px;
    .title {
      font-size: 18px;
      color: #262626;
      font-weight: bold;
      position: relative;
      padding-bottom: 10px;
      width: fit-content;
    }
    .title::before {
      content: "";
      width: 100%;
      height: 2px;
      // background: var(--main-color);
      position: absolute;
      left: 0;
      bottom: -1px;
    }
    .more {
      font-size: 14px;
      color: #333333;
      // padding-bottom: 10px;
      padding-right: 20px;
      a {
        display: block;
      }
    }
    .more:hover {
      color: var(--main-color);
    }
  }

  .table {
    margin: 0 20px;
    margin-top: 20px;
    background: none;
  }
}
.Insight1 {
  background: #fff;
  padding: 10px 0px;
  .allTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cecece;
    margin: 0 10px;
    .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      position: relative;
      padding-bottom: 5px;
      width: fit-content;
    }
    .title::before {
      content: "";
      width: 100%;
      height: 2px;
      background: var(--main-color);
      position: absolute;
      left: 0;
      bottom: -1px;
    }
    .more {
      font-size: 14px;
      color: #333333;
      padding-bottom: 5px;
      a {
        display: block;
      }
    }
    .more:hover {
      color: var(--main-color);
    }
    ::v-deep .el-table .el-table__cell th {
      padding-left: 1px !important;
      padding-right: 1px !important;
      font-size: 16px;
      color: #333;
    }
    ::v-deep .el-table .el-table__cell {
      padding-left: 1px !important;
      padding-right: 1px !important;
      font-size: 14px;
      color: #333;
    }
    .table {
      margin: 0 5px;
      background: none;
      overflow-x: hidden;
    }
    .cell {
      font-size: 14px !important;
    }
  }

  .table {
    margin: 0 5px;
    background: none;
  }
  .cell {
    font-size: 8px;
  }

  ::v-deep .el-table .el-table__cell {
    padding-left: 1px !important;
    padding-right: 1px !important;
    font-size: 14px;
    color: #333;
  }
  ::v-deep {
    .el-table .el-table__row:hover {
      cursor: pointer;
      .cell {
        color: var(--main-color);
      }
    }
  }
  .table ::v-deep .el-table__empty-block {
    min-height: 70px !important;
  }
}
::v-deep.el-table .el-table__cell {
  padding-left: 40px !important;
  padding-right: 40px !important;
  font-size: 14px;
  color: #333;
}
::v-deep {
  .el-table .el-table__row:hover {
    cursor: pointer;
    .cell {
      color: var(--main-color);
    }
  }
}
//scss
.el-table ::v-deep tbody tr:hover > td {
  background-color: transparent !important;
}

// .table ::v-deep .el-table--fit {
//   padding: 20px;
// }
.table ::v-deep .el-table,
.el-table__expanded-cell {
  background-color: transparent;
}

.table ::v-deep .el-table tr {
  background-color: transparent !important;
}
.table ::v-deep .el-table tr td:first-child,
.table ::v-deep .el-table tr th:first-child {
  border-left: 1px solid #ebeef5 !important;
}
.table ::v-deep .el-table tr td:last-child,
.table ::v-deep .el-table tr th:nth-child(3) {
  border-right: 1px solid #ebeef5 !important;
}
.table ::v-deep .el-table tr th {
  border-top: 1px solid #ebeef5 !important;
}
.table ::v-deep .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}
.el-table::before {
  //去除底部白线
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
}
.table,
.table ::v-deep .el-table__empty-block {
  min-height: 200px !important;
}

.el-timeline-item {
  padding-bottom: 22px !important;
}

.el-timeline-item__node {
  position: absolute;
  // background-color: #E4E7ED;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-timeline-item__node
  el-timeline-item__node--normal
  el-timeline-item__node-- {
  position: absolute;
  // background-color: #E4E7ED;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  height: 500px;
  white-space: nowrap;
  overflow-x: scroll;
}

.block:-webkit-scrollbar {
  width: 4px;
  height: 1px;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  //background-color: #8c8c8c;
  background-color: rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-track {
  background-color: #f6f6f6;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border: 0;
}

.el-timeline-item:after,
.el-timeline-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: white;
  z-index: -2;
  border-radius: 4px;
}

.el-timeline-item:hover {
  z-index: 1;
  background: transparent;
}

.el-timeline-item:before {
  transition: 0.3s;
  background: #fafafa;
  z-index: -1;
}

.el-timeline-item:hover:after,
.el-timeline-item:hover:before {
  width: 100%;
}

::v-deep .el-timeline-item__content {
  height: 44px;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 0 20px 0;
}

::v-deep .el-timeline-item__content:hover {
  color: var(--main-color);
}

::v-deep .el-timeline-item__timestamp.is-bottom {
  margin-top: 20px;
  font-size: 12px;
  line-height: 14px;
  color: #a1a8b3;
}

::v-deep .el-timeline-item__tail {
  top: 10px;
  left: 7px;
  border: 1px dashed #f2f7ff;
}

::v-deep .el-timeline-item__node {
  border: 1px solid skyblue;
  background: white;
  top: 4px;
  left: 2px;
}

.report {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 36px;
  height: 20px;
  background: rgba(28, 28, 28, 0.9);
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  z-index: 999;
}

// .page {
//   background: url(https://imgs.ebrun.com/master/2021/images/ico_report_page.png)
//     no-repeat 0;
//   background-size: 13px 16px;
//   padding-left: 17px;
//   font-size: 12px;
//   color: #a1a8b3;
//   line-height: 28px;
//   height: 28px;
//   text-align: center;
//   margin-left: 10px;
// }

.page1 {
  padding-left: 10px;
  font-size: 12px;
  color: #a1a8b3;
  line-height: 28px;
  height: 28px;
  text-align: center;
  // margin-left: 10px;
}

.pageLook {
  display: block;
  width: 56px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: #1a79ff;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  margin-right: 10px;
}
.el-button--primary.is-plain {
  display: inline-block;
  width: 170px;
  // height: 40px;
  font-size: 14px;
  color: #a1a8b3;
  // line-height: 40px;
  border: 1px solid #a1a8b3;
  background-color: #fff;
  border-radius: 2px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover{
    background-color: var(--main-color);
    color: #fff;
    border-color: var(--main-color);
  }
}

.el-button--mini {
  color: #a1a8b3;
  background-color: #f2f7ff;
}

.el-button--mini:hover {
  color: #fff;
  background: #1a79ff;
}

::v-deep .el-tabs__item {
  font-weight: 700;
  font-size: 22px;
}


.label-box{
  display: inline-block;
  height: 20px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a8b3;
  margin-right: 10px;
  background-color: #f2f7ff;
  border-radius: 10px;
  transition: all .3s;
  cursor: pointer;
  // max-width: 8em;
  // text-overflow: ellipsis;
  overflow: hidden;
  &:hover{
    color: #fff;
    background-color: var(--main-color);
  }
}

// 移动端
.centerWidth1 {
  // height: 156px;
 padding: 0  4px ;
//  margin-right: -0.3rem;
 
}

.navBox {
  display: inline-block;
  width: 22%;
  height: 70px;
  margin: 0 7.5px 8px 0;
  padding-top: 10px;
  -webkit-box-shadow: 0 1px 12px 0 #e6ebf2;
  -moz-box-shadow: 0 1px 12px 0 #e6ebf2;
  box-shadow: 0 1px 12px 0 #e6ebf2;
  border-radius: 2px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.navBg {
  display: inline-block;
  width: 28px;
  height: 28px;
}

.nav1{
  display: flex;
  justify-content: space-between;
  .item{
    // flex: 1;
    width: 24%;
    .navBox{
      display: block;
      width: initial;
      margin: 0;
    }
  }
}



</style>

