/*
 * @Author: 自由命名
 * @Date: 2023-11-06 14:40:39
 * @LastEditors: 自由命名
 * @LastEditTime: 2023-12-01 14:44:13
 * @FilePath: \zixun-vue\src\api\index.js
 * @filePurpose: 
 */
import request from '@/utils/request'

// 获取导航
export function navList() {
  return request({
      url: `/api/banner/list`,
      method: 'get',
  })
}

//  获取首页新闻列表 /api/category/recommend/{limit}
export function categoryRecommend() {
  return request({
      url: `/api/category/recommend/3`,
      method: 'get',
  })
}


// 首页新闻推荐  /api/recommend/list/{limit}
export function recommend() {
  return request({
      url: `/api/recommend/list/6`,
      method: 'get',
  })
}

//  获取首页商机洞察 /api/news/businessList
export function businessList() {
  return request({
      url: `/api/news/businessList`,
      method: 'get',
      params:{
        limit:5
      }
  })
}

//  获取首页行业报告列表 /api/report/list
export function reportList() {
  return request({
      url: `/api/report/list`,
      method: 'get',
      params:{
        pageNum: 1,
        pageSize:6
      }
  })
}


// 尾部底部导航数据  /api/column/column
export function columnNav() {
  return request({
      url: `/api/column/column`,
      method: 'get',
  })
}

// 尾部联系我们数据  /api/column/contact
export function columnContact() {
  return request({
      url: `/api/column/contact`,
      method: 'get',
  })
}

// 尾部二维码数据  /api/column/qrcode
export function columnQrcode() {
  return request({
      url: `/api/column/qrcode`,
      method: 'get',
  })
}

// 微信分享配置 /api/wxShare
export function shares(data) {
  return request({
      url: `/api/wxShare`,
      method: 'get',
      params:{
        requestUrl: data
      }
  })
}
// 登录   获取验证码
// export function randomImage(id) {
//   return request({
//       url: `/thirdPlatform/home/randomImage/${id}`,
//       method: 'get',
//   })
// }

// 获取首页banner右侧广告位
export function getAds(data) {
  return request({
      url: `/api/space/list`,
      method: 'get',
      params: data
  })
}

// 获取 科技前沿/新闻动态/商机洞察 列表
export function getNews(data) {
  return request({
      url: `/api/news/list`,
      method: 'get',
      params: data
  })
}

// 获取 科技前沿/新闻动态 列表
export function getNewsEn(data) {
  return request({
      url: `/api/news/listEn`,
      method: 'get',
      params: data
  })
}

// 获取 科技前沿/新闻动态/商机洞察 分类 无分页
export function getNewsCates(data) {
  return request({
      url: `/api/category/optionSelect`,
      method: 'get',
      params: data
  })
}

// 获取 科技前沿/新闻动态/商机洞察 详情
export function getNewsDetail(id, data) {
  return request({
      url: `/api/news/${id}`,
      method: 'get',
      params: data
  })
}


// 获取 期刊报告分类 
export function getCates(data) {
  return request({
      url: `/api/report/category`,
      method: 'get',
      params: data
  })
}

// 获取 期刊报告 列表
export function getReports(data) {
  return request({
      url: `/api/report/list`,
      method: 'get',
      params: data
  })
}

// 获取 期刊报告 详情
export function getReportDetail(id, data) {
  return request({
      url: `/api/report/${id}`,
      method: 'get',
      params: data
  })
}

// 收藏/取消收藏
export function collectFetch(data) {
  return request({
      url: `/api/collect/addOrDelete`,
      method: 'post',
      data: data
  })
}

// 点赞/取消点赞
export function likeFetch(data) {
  return request({
      url: `/api/kudos/addOrDelete`,
      method: 'post',
      data: data
  })
}