module.exports = {
    common: {
        login:  "登录",
        submit: "提交",
        reset: "重置",
        cancel: "取消",
        noData: '暂无数据',
        article: '正文',
        webName: '油气知识',
        all: '全部',
        defaultKeywords: '油气知识',
        defaultdDscription: '洞悉油气行业变化，体验AI大模型，就来油气知识！',
        loginRegi: '登录/注册',
        confirm: '确认',
        backtop: '返回顶部',
        more: '更多',
        view: '查看',
        share: '分享',
        collect: '收藏',
        like: '点赞',
        wxShareTip: '打开微信“扫一扫”，分享给朋友和朋友圈',
        label: '标签',
        search: '搜索'
    },
    index: {
        technology: "科技前沿",
        news: "新闻动态",
        opportunity: "商机洞察",
        report: "期刊报告",
        more: '查看更多资讯',
        navTitle: '油气知识 - AI大模型油气资讯平台',
        navKeywords: '油气知识，油气资讯，油气知识平台，油气资讯平台，油气AI大模型，AI大模型资讯，油气商机，油气报告，油气期刊',
        navDescription: '油气知识（cn.oilgasinfoai.com）AI大模型油气资讯平台，提供一站式智能信息服务，浏览学习全方位行业资讯，涵盖新闻动态、科技前沿、行业报告、商机洞察等；智能搜索及AI互动对话，创造智能化、个性化用户体验。',
        mLoginTip: '点击登录，体验更多服务'
    },
    header: {
        webName: '油气知识-AI大模型油气资讯平台, ',
        login: "请登录",
        service: "官方客服",
        welcome: '您好, {username} 欢迎来到油气知识',
        oilDao: '油气岛',
        oilExpo: '油气展馆',
        oilCommunity: '油气社区',
        oilTalent: '油气人才',
        mobileTerminal: '移动端',
        feedback: '意见反馈',
        searchKeyword: '油气',
        searchHotword1: '绿色能源',
        searchHotword2: '石油科技',
        searchHotword3: '产品研发',
        searchHotword4: '商机发现',
        searchHotword5: '知识科普',
    },
    ai: {
        navTitle: 'AI资讯 - AI大模型资讯平台 - 油气知识',
        navKeywords: 'AI大模型，AI资讯，AI大模型资讯',
        navDescription: '油气知识（cn.oilgasinfoai.com）AI大模型油气资讯平台，用户可以通过问答的形式，进行油气新闻的查阅，支持新闻、科技、经济等全方位问答。',
        newChat: "新建对话",
        historyChat: "历史对话",
        noHistory: "暂无历史记录，快去对话吧",
        aiName: "AI问答小助手",
        tip: "提问或输入你想写的内容，我会立即提供解答和帮助",
        serviceability: "以下是我能提供的服务能力",
        aiChat: "AI对话",
        aiChatTip: "用户可以通过问答的形式，进行油气新闻的查阅，支持新闻、科技、经济等全方位问答。",
        newsSummary: "新闻归纳",
        newsSummaryTip: "支持单篇新闻总结归纳，包括文章摘要、关键词生成以及文章主旨总结等。",
        membership: "开通会员",
        membershipTip: "AI资讯对话属于付费权益，成为付费用户可拥有更多对话次数，",
        membershipBtn: "立即开通。",
        customizedService: "定制化服务",
        customizedServiceTip: "如果您想拥有您企业专属的定制化版GPT油气知识，请联系我们，",
        customizedServiceBtn: "联系方式。",
        aiInput: "请输入需要咨询的问题",
        aiInputLoginTip: "后，可向我发送问题",
        contactMethod: "联系方式",
        qaAdvice: "问答建议",
        qaAdviceQ: "提出问题：",
        qaAdviceA: "期望回答：",
        chatNumTip1: "您共拥有",
        chatNumTip2: "条对话次数，已消耗",
        chatNumTip3: "条对话次数。",
        chatNumTip4: '更多付费权益',
        chatNumUseUpTitle: "温馨提示",
        chatNumUseUpTip: "您的免费对话次数已用尽，成为付费用户拥有更多对话次数",
        activate: "立即开通",
        buy: '点击购买',
        contact: '联系人',
        phone: '电话',
        email: '邮箱',
        tipTitle: '提示',
        typeLimitTip: '最多可以输入3000个字符',
        timeoutTip: '当前超出30min未进行对话，请刷新重试',
        generateTip: '正在生成，请稍候...',
        unabilityTip: '当前对话轮次超出小安的能力限制啦，我们将重新开始新一轮对话。',
        replicatingTip: '复制成功',
        findDocTip1: '找到了',
        findDocTip2: '篇文档作为参考',
        findDocTip3: '找到了第',
        findDocTip4: '篇文档',
    },
    technology: {
        navTitle: '科技前沿资讯 - 油气行业AI大模型资讯平台-油气知识',
        navKeywords: '科技资讯，科技前沿资讯，油气行业资讯，油气资讯',
        navDescription: '油气知识（cn.oilgasinfoai.com）AI大模型油气资讯平台，在科技前沿资讯频道，看油气行业深度报道，随时随地掌握行业资讯及分析解读。'
    },
    technologyDetail: {
        navTitle: '油气知识科技前沿',
        navKeywords: '油气知识，油气资讯，科技前沿'
    },
    news: {
        navTitle: '新闻动态资讯 - 油气行业AI大模型资讯平台-油气知识',
        navKeywords: '新闻动态，新闻动态资讯,油气行业资讯，油气资讯',
        navDescription: '油气知识（cn.oilgasinfoai.com）AI大模型油气资讯平台，在新闻动态讯频道，看油气行业新闻报道，随时随地掌握行业资讯及分析解读。'
    },
    newsDetail: {
        navTitle: '油气知识新闻动态',
        navKeywords: '油气知识，油气资讯，新闻动态',
        next: '下一篇',
        likeTip: '好文章，值得被更多人看到',
        firstLike: '求首赞',
        wx: '微信',
        wxFriend: '朋友圈',
        relateNews: '相关阅读',
    },
    report: {
        navTitle: '期刊报告 - 油气行业AI大模型资讯平台-油气知识',
        navKeywords: '油气期刊，油气报告，油气期刊报告，油气知识',
        navDescription: '油气知识（cn.oilgasinfoai.com）AI大模型油气资讯平台，在期刊报告频道，看油气行业海量报告，了解行业发展动向与变迁趋势。',
        moreReports: '查看更多报告',
        moreJournals: '查看更多期刊',
        pages: '页',
        viewReport: '查看报告',
        reportPage: '报告页数',
        download: '下载',
        overflow: '内容概览',
        viewTip1: '更多详细内容，请登录后查看',
        viewTip2: '注册登录查看完整报告',
        viewTip3: '更多详细内容，请点击查看',
        viewTip4: '查看完整报告',
        moreRJ: '更多期刊报告',
        receive: '领取',
    },
    reports: {
        navTitle: '油气行业市场报告_油气行业发展趋势报告_油气知识',
        navKeywords: '油气报告，油气行业报告，油气行业研究报告，市场分析报告，产业发展报告',
        navDescription: '油气知识（cn.oilgasinfoai.com）AI大模型油气资讯平台，为您提供专业的油气行业研究报告、可行性分析报告、市场调研报告。',
    },
    journals: {
        navTitle: '油气行业期刊报告_油气行业专业期刊_油气知识',
        navKeywords: '油气期刊，油气行业期刊，油气期刊，油气报告',
        navDescription: '油气知识（cn.oilgasinfoai.com）AI大模型油气资讯平台，为您提供专业的油气行业研究期刊报告、可行性分析期刊报告、市场调研期刊报告。',
    },
    reportDetail: {
        navTitle: '油气知识期刊报告',
        navKeywords: '油气期刊，油气报告，油气期刊报告，期刊报告',
    },
    opportunity: {
        navTitle: '商机洞察 - 油气行业商机 - 企油气知识',
        navKeywords: '商机市场，商机，油气知识，采购，求购，招标，投标，询价，商机洞察',
        navDescription: '油气知识（cn.oilgasinfoai.com）AI大模型油气资讯平台，为您提供海量的商机数据，洞察商业机会，让你足不出户成交生意。',
        moreR: '查看更多商机',
        language: '语言',
        type: '类型',
        sort: '排序',
        timeSort: '按信息发布时间',
        announcement: '公告名称',
        company: '发布单位名称',
        announceTime: '发布时间',
        operate: '操作',
        detail: '查看详情',
        totalTip1: '共',
        totalTip2: '条数据',
        tender:'招标',
        rAnnouncement: '公示',
        aCompany: '发布主体',
        aDetail: '公告详情',
        startTime: '发布时间',
        endTime: '截止时间',
        enterpriseName: '企业名称',
        brief: '公告概要'
    },
    opportunityDetail: {
        navTitle: '油气知识商机洞察',
        navKeywords: '油气知识，油气知识商机，油气知识商机洞察，商机',
    },
    aiDialog: {
        serviceTip1: '我是问答小助手，对于文章您有什么问题，我会尽力帮您解答。',
        serviceTip2: '这篇新闻的摘要是什么',
        serviceTip3: '这篇新闻的关键词是什么',
        useNumTip1: '您共拥有',
        useNumTip2: '条对话次数，已消耗',
        useNumTip3: '条对话次数。',
        aiName: '问答小助手',
        aiTip1: '付费权益及企业专属定制化服务',
        aiTip2: 'AI资讯对话属于付费权益，成为付费用户可拥有更多对话次数',
        aiTip3: '如果您想拥有您企业专属的定制化版GPT油气知识，请联系我们 ',
    },
    search: {
        navTitle: '{title} - 油气行业商机 - 企油气知识',
        navKeywords: '{keyword} ，油气知识，油气资讯',
        navDescription: '油气知识（cn.oilgasinfoai.com）为您提供各种{keyword} 的内容信息，{keyword} 资讯，{keyword} 商机，{keyword} 期刊报告等，查找更多的{keyword} 内容信息请上油气知识。',
        hotSearch: '热门搜索',
        searchResultTip: '找到约{total}条相关结果',
        countdown1: '距结束',
        countdown2: '天',
    },
    user: {
        user: '个人中心',
        logout: '退出登录',
        location: '当前位置',
        info: '个人信息',
        avatar: '头像',
        uploadAvatar: '上传头像',
        username: '昵称',
        usernameTip: '请输入昵称，最多可输入20字',
        realname: '姓名',
        realnameTip: '请输入姓名',
        sex: '性别',
        sexTip: '请选择性别',
        birthday: '出生年月',
        birthdayTip: '选择日期',
        industry: '所在行业',
        industryTip: '请输入所在行业',
        company: '所属公司',
        companyTip: '请输入所属公司',
        duty: '职务',
        dutyTip: '请输入职务',
        interest: '阅读兴趣',
        interestTip: '请输入阅读兴趣',
        myCollection: '我的收藏',
        unfavorite: '取消收藏',
        myOrders: '我的订单',
        message: '系统消息',
        secure: '账户安全',
        bindPhone: '手机绑定',
        bindEmail: '邮箱绑定',
        setPwd: '密码设置',
        bindWx: '微信绑定',
        immediateBinding: '立即绑定',
        unbindImmediately: '立即解绑',
        areaCode: '区号',
        areaCodeTip: '请选择区号',
        phone: '手机号',
        phoneTip: '请输入手机号',
        code: '验证码',
        codeTip: '请输入验证码',
        modifyPhone: '修改手机号',
        sendCode: '发送验证码',
        bindingPhone: '绑定手机号',
        bindingMail: '绑定邮箱',
        modifyMail: '修改邮箱',
        mail: '邮箱',
        mailTip: '请输入邮箱',
        account: '账户',
        accountTip: '请输入邮箱/手机号',
        password: '密码',
        passwordTip: '请输入密码',
        requirePas: '确认密码',
        requirePasTip: '请再次输入密码',
        modifyPwd: '修改密码',
    },
    right: {
        latestBusiness: '最新商机',
        latestReport: '行业报告',
        latestInfo: '最新资讯',
        latestNews: '最新动态',
        latestRJ: '期刊报告',
    },
    member: {
        joinMember: '开通会员',
        memberLevel1: '青铜会员',
        memberLevel2: '铂金会员',
        memberLevel3: '黄金会员',
        memberBenefits: '会员权益',
        dialogNum: '对话次数',
        payMethod: '选择支付方式',
        payWechat: '微信',
        payAlipay: '支付宝',
        payPaypal: 'Paypal支付',
        agreeTip: '我已阅读并接受 ',
        agreeTip1: '《油气知识用户购买协议》',
        times: '次',
        privilegeTip: '尊享特权',
        privilege: '特权',
        interests: '权益',
        weChatPay: '微信支付',
        paymentAmount: '支付金额',
        wechatScanTip: '使用微信扫一扫\n扫描二维码支付',
    },
    extends: {
        collectTip: '此操作将取消收藏该文章, 是否继续？',
        rejectCollect: '取消收藏成功！',
        resolveCollect: '收藏文章成功！',
        likeTip: '此操作将取消点赞, 是否继续？',
        rejectLike: '取消点赞成功！',
        resolveLike: '点赞成功！'
    }
}