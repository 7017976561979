<template>
  <div class="rightBox">

    <div class="module" v-for="(module, index) in modulesData" :key="index">
      <div class="news" v-if="module.title">
        <span class="newsTitle">
          <span>{{module.title}}</span>
          <p></p>
        </span>
        <a :href="'/' + module.path">{{ $t('common.more') }}</a>
      </div>

      <el-tabs v-model="tabActive" @tab-click="handleClick(index)" v-if="module.hasNavTab">
        <el-tab-pane :label="nav.label" v-for="(nav, navIndex) in module.navs" :key="navIndex"></el-tab-pane>
      </el-tabs>

      <!-- 有图资讯（新闻动态，科技前沿） -->
      <template v-if="module.type == 'news' && module.dataList?.length">
        <div class="m-info-box" v-for="item in module.dataList" :key="item.id" @click="toDetail(item.id, module.path)">
          <div class="m-info-txt">
            <p class="m-info-title twoLine">{{item[trans('title,titleEn')]}}</p>
            <div class="m-info-time">
              <span class="">{{item.createTime}}</span>
            </div>
          </div>
          <img :src="item[trans('img,imgEn')] ? baseURL + item[trans('img,imgEn')] : ''" alt="" class="m-info-img" />
        </div>
        <div style="height: 50px; text-align: center;margin-top: 20px;" v-if="module.hasMoreBtn">
          <el-button type="primary" plain @click="$router.push(`/${module.path}`)">{{ $t('index.more') }}</el-button>
        </div>
      </template>

      <!-- 期刊报告 -->
      <template v-if="module.type == 'report' && module.dataList?.length">
        <!-- <div class="m-r-report-box">
          <div class="newsGrand" v-for="item in module.dataList" :key="item.id" @click="toDetail(item.id, module.path)">
            <div style="position: relative;cursor: pointer;">
              <img :src="item.img ? baseURL + item.img : ''" alt="" class="newsBg" />
            </div>
            <div class="m-r-report-txt">
              <p class="newsT1 twoLine">{{item.title}}</p>
              <div class="newsT2">
                <span><i class="el-icon-document"></i> {{item.page}} {{$t('report.pages')}}</span>
                <span class="industryReportB">{{ $t('common.view') }}</span>
              </div>
            </div>
          </div>
        </div> -->
        <div class="chineseBox" v-for="item in module.dataList" :key="item.id" style="cursor: pointer"  @click="toDetail(item.id, module.path)">
          <div class="chineseR">
            <img
              :src="item.img ? baseURL + item.img : ''"
              alt=""/>
            <div>
              <p class="reportTitle">{{ item.title }}</p>
              <div>
                <p class="chinesePage"><i class="el-icon-document"></i> {{ item.page + " " + $t("report.pages") }}</p>
                <p class="chineseButton">{{ $t('common.view') }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- 商机 -->
      <template v-if="module.type == 'opportunity' && module.dataList?.length">
        <div class="block">
          <el-timeline>
            <el-timeline-item
              v-for="(item, opporIndex) in module.dataList"
              :key="opporIndex"
              :timestamp="item.createTime"
            >
              <div class="twoLine" style="cursor: pointer;padding-right: 1em;" @click="toDetail(item.id, module.path)">{{ item.title }}</div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div style="height: 50px; text-align: center;" v-if="module.hasMoreBtn">
          <el-button type="primary" plain @click="$router.push(`/${module.path}`)">{{ $t('opportunity.moreR') }}</el-button>
        </div>
      </template>

      <!-- 无图资讯（新闻动态，科技前沿） -->
      <template v-if="module.type == 'technology' && module.dataList?.length">
        <div class="newsGrand1" v-for="item in module.dataList" :key="item.id">
          <p class="newsT1 twoLine" @click="toDetail(item.id, module.path)">{{item[trans('title,titleEn')]}}</p>
          <div class="newsT2">
            <div>
              <span class="label-box" v-for="(label, labelIndex) in (item[trans('label,labelEn')] ? item[trans('label,labelEn')].split(',') : [])"
                :key="labelIndex">{{label}}</span>
            </div>
            <span class="">{{item.createTime}}</span>
          </div>
        </div>
      </template>
      
    </div>

    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
  </div>
</template>
<script>
import mixins from "@/utils/mixins.js";
import LoginTip from "@/components/LoginTip.vue";
import { mapState } from 'vuex'

import {
  getNews,
  getNewsEn,
  getReports
} from "@/api/index";

export default {
  components: { LoginTip },
  mixins: [mixins],
  props: {
    // 'opportunity', 'report', 'news', 'technology'
    modules: {
      type: Array,
      default(){
        return [
          {
            type: 'opportunity',
            title: '最新商机',
            hasMoreBtn: true,
            dataNum: 5,
            primaryTypeId: 3
          },
          {
            type: 'report',
            title: '行业报告',
            hasMoreBtn: true,
            dataNum: 5,
            primaryTypeId: 2
          },
          {
            type: 'news',
            title: '最新资讯',
            hasMoreBtn: true,
            dataNum: 5,
            primaryTypeId: 4
          },
          {
            type: 'technology',
            title: '最新动态',
            hasMoreBtn: true,
            dataNum: 5,
            primaryTypeId: 1
          },
        ]
      }
    }
  },
  computed: {
    ...mapState({
      'access_token': 'access_token', 
      'userInfo': 'user'
    })
  },
  data() {
    return {
      modulesData: [],
      dialogVisible: false,
      tabActive: 0,

    };
  },
  created(){
    this.modulesData = this.modules

    this.modulesData.map((item, index) => {
      this.getList(index);
    })
  },
  methods: {
    async getList(index){
      let temp = this.modulesData[index];
      let primaryTypeid = temp.primaryTypeId;
      if(temp.navs){
        primaryTypeid = temp.navs[this.tabActive].primaryTypeId
      }
      let fn = temp.type == 'report' ? getReports : temp.type == 'opportunity' ? getNews :  this.$i18n.locale == 'en' ? getNewsEn : getNews;
      let res = await fn({
        primaryTypeid,
        secondaryTypeid: temp.type == 'opportunity' ? (this.$i18n.locale == 'en' ? 22 : 15) : '',
        secondaryTypeids: temp.type == 'report' ? (this.$i18n.locale == 'en' ? '6,7' : '4,8') : '',
        pageNum: 1,
        pageSize: this.modulesData[index].dataNum,
        isSort: 2,
        draftStatus: 0,
        articleFlag: 1,
      })
      this.$set(this.modulesData[index], 'dataList', res.rows)
    },
    toDetail(id, type) {
      if('news technology report'.includes(type)){
        this.$router.push(`/${type}/` + id);
      }else{
        if (this.userInfo.userId) {
          this.$router.push(`/${type}/` + id);
        } else {
          this.dialogVisible = true;
        }
      }
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    handleClick(index){
      this.modulesData[index].path = this.modulesData[index].navs[this.tabActive].type
      this.getList(index);
    },
  }
};
</script>
<style lang="scss" scoped>
.module{
  & + .module{
    margin-top: 20px;
  }
  ::v-deep .el-tabs__header{
    margin-bottom: 0;
    .el-tabs__nav-wrap::after{
      display: none;
    }
    .el-tabs__item{
      font-size: 16px;
      height: 30px;
      line-height: 30px;
      & + .el-tabs__item{
        padding-left: 0;
      }
      &:hover{
        color: var(--main-color);
      }
    }
    .el-tabs__active-bar{
      background-color: var(--main-color);
    }
  }
}
.label-box{
  display: inline-block;
  height: 20px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a8b3;
  margin-right: 10px;
  background-color: #f2f7ff;
  border-radius: 10px;
  transition: all .3s;
  cursor: pointer;
  max-width: 8em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover{
    color: #fff;
    background-color: var(--main-color);
  }
}
.rightBox{
  margin-top: 20px;
}
.news {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > a{
    font-size: 14px;
    color: #999;
  }
  & > a:hover{
    color: var(--main-color);
  }
}

.newsTitle {
  position: relative;
  z-index: 0;
  padding-left: .8em;
}

.newsTitle > p {
  width: 2px;
  height: 100%;
  background: var(--main-color);
  position: absolute;
  left: 0;
  top: 0;
  border-bottom: none;
  // z-index: -1;
}

.newsGrand {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.newsGrand1{
  margin-top: 20px;
}

.newsBg {
  width: 120px;
  height: 80px;
}

.newsT1 {
  height: 2.7em;
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  cursor: pointer;
  &:hover{
    color: var(--main-color);
  }
}

.newsT2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  font-size: 12px;
  line-height: 18px;
  color: #a1a8b3;
}

.industryReport {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.industryReportTitle {
  position: relative;
  z-index: 0;
}

.industryReportColor {
  width: 100%;
  height: 12px;
  background: #a6cbff;
  position: absolute;
  left: 0;
  top: 10px;
  border-bottom: none;
  z-index: -1;
}

.industryReportGrand {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}


.industryReportBT {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 36px;
  height: 20px;
  background: rgba(28, 28, 28, 0.9);
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  z-index: 1;
}

.industryReportT1 {
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.industryReportT2 {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  height: 28px;
  overflow: hidden;
}


.industryReportB {
  display: block;
  width: 56px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: #1a79ff;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.el-timeline-item__node {
  position: absolute;
  // background-color: #E4E7ED;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-timeline-item__node
  el-timeline-item__node--normal
  el-timeline-item__node-- {
  position: absolute;
  // background-color: #E4E7ED;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  height: 440px;
  margin-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  & ::v-deep .el-timeline-item__content{
    padding-top: 0;
  }
}
.block::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.block::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  //background-color: #8c8c8c;
  background-color: rgba(0, 0, 0, 0.25);
}

::v-deep.el-timeline-item {
  // background-image: linear-gradient(to right, red, yellow);
  background-size: 200% 100%;
  transition: background-position 0.5s;
}

::v-deep.el-timeline-item:hover {
  background-image: linear-gradient(to right, white, rgb(248, 248, 248));
  z-index: 1;
  background-position: -100% 0;
}

.el-timeline-item:before {
  transition: 0.3s;
  background: #fafafa;
  z-index: -1;
}

.el-timeline-item:hover:after,
.el-timeline-item:hover:before {
  width: 100%;
}

::v-deep .el-timeline-item__content {
  height: 44px;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 0 20px 0;
}

::v-deep .el-timeline-item__content:hover {
  color: var(--main-color);
}

.block ::v-deep .el-timeline-item__timestamp.is-bottom {
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  color: #a1a8b3;
}

::v-deep .el-timeline-item__tail {
  top: 10px;
  left: 7px;
  border: 1px dashed #f2f7ff;
}

::v-deep .el-timeline-item__node {
  border: 1px solid skyblue;
  background: white;
  top: 4px;
  left: 2px;
}

.el-button--primary.is-plain {
  display: inline-block;
  width: 170px;
  // height: 40px;
  font-size: 14px;
  color: #a1a8b3;
  // line-height: 40px;
  border: 1px solid #a1a8b3;
  border-radius: 2px;
  transition: all 0.3s;
  cursor: pointer;
  background: #fff;
  &:active{
    background-color: var(--main-color);
    color: #fff;
    border-color: var(--main-color);
  }
}

.el-button--mini{
  color: #a1a8b3;
  background-color: #f2f7ff;
}

.el-button--mini:hover {
  color: #fff;
  background: #1a79ff;
}


.m-r-report-box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .newsGrand{
    width: 45%;
    height: 210px;
    flex-direction: column;
    background-color: #f5f9ff;
    border-radius: 4px;
    justify-content: initial;
    .newsBg{
      width: 100%;
      height: 110px;
      vertical-align: top;
    }
    .m-r-report-txt{
      padding: 8px;
    }
    .newsT2{
      margin-top: 10px;
    }
  }
}

.m-info-box{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .m-info-txt{
    flex: 1;
    display: flex;
    margin-right: 14px;
    flex-direction: column;
    justify-content: space-between;
    .m-info-title{
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      cursor: pointer;
      &:hover{
        color: var(--main-color);
      }
    }
    .m-info-time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;
      font-size: 12px;
      line-height: 18px;
      color: #a1a8b3;
    }
  }
  .m-info-img{
    width: 120px;
    height: 70px;
  }

}
.chineseBox {
  display: flex;
}
.chineseR {
  margin-top: 16px;
  width: 100%;
  // height: 120px;
  overflow: hidden;
  border-radius: 4px;
  // padding: 0 0 16px;
  // margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  // border-bottom: 1px solid #f0f0f0;
  img {
    width: 90px;
    height: 120px;
    margin-right: 10px;
  }
  & > div {
    // padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    & > div{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .chinesePage{
    padding-left: 0;
    line-height: initial;
    background: none;
  }
}

.reportTitle {
  font-size: 14px;
  color: #17181a;
  text-align: left;
  line-height: 24px;
  font-weight: bold;
  width: 100%;
  height: 50px;
  overflow: hidden;
  overflow: hidden; //超出盒子宽度内容，便溢出隐藏
  text-overflow: ellipsis; //用...省略号显示
  display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
  -webkit-box-orient: vertical; //从上到下垂直排列子元素
  -webkit-line-clamp: 2; //显示的行数
}

.chinesePage {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAA0CAYAAAD8H6qwAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKqADAAQAAAABAAAANAAAAAALyozoAAAB2UlEQVRoBe2ZvU7DMBDHzykb37DCOyBAIIFYkSisiEpUYuOh2KCtVFrGNm1VJiYehDdAbLUPuzSRZRpiJ8F1wVnqO1v2r/87XxKH1NvhGWN4h4hbYPEiQD5KBMrXV+cvOssGs4AUYAi4SBHCWrt/ogVqW0kZSsAiG4X1x/BY9k9rB9OcNn2IsESB9WrtztFP65L7ZgflATeVCyLbRbfV9eL5CbyXMDitVsqvsU9qzFzRmAVhmQEOHp66h7FPargDyqF4zq4AhUGj1T+QGMdNp0AFEd/cqyMcCWX3ZVjnQMdwCGtIcdhodfYiWDdBBR2HpQyGtWZ/V5jugo5ZYZ0RypXt7iwIWqcvxA0K8GxdUULIm6kw/KawaR00CMhtFljroa9elntc0e00VdU7mHVF0wCT+j1okjJZ/cY5mveNQGwksaEmuarNbRz6vG8E4kFdzKFNOBloDGq6QFHjjUGz1sEIOAp9ZOv+Gueobh3UBdAdZ6yo7sRFj/OgRStqnKO+jqaE4O/mqK+j/zb0KX/817rnZjP5OpqUA/55VFXG11FVEcWem13vQZXI5Ta9orklVCbwiiqC5Da9orklVCb49i1U6XfGnJ/QZzn4ty3z18Faxq8UtmCj079Pe7/MwOy4YJoAAAAASUVORK5CYII=)
    no-repeat left 10px;
  background-size: 8px 11px;
  font-size: 12px;
  color: #a1a8b3;
  line-height: 35px;
  padding-left: 12px;
}

.chineseButton {
  width: 56px;
  height: 24px;
  background: #1a79ff;
  border-radius: 0.06rem;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;
}



</style>


