<template>
  <div class="rightBox">

    <div class="module" v-for="(module, index) in modulesData" :key="index">
      <div class="news">
        <span class="newsTitle">
          <span>{{module.title}}</span>
          <p></p>
        </span>
        <a :href="'/' + module.path">{{ $t('common.more') }}</a>
      </div>
      <!-- 有图资讯（新闻动态，科技前沿） -->
      <template v-if="module.type == 'news' && module.dataList?.length">
        <div class="newsGrand" v-for="item in module.dataList" :key="item.id" @click="toDetail(item.id, module.path)">
          <img :src="item[trans('img,imgEn')] ? baseURL + item[trans('img,imgEn')] : ''" alt="" class="newsBg" />
          <div style="width: 165px;display: flex;flex-direction: column;justify-content: space-between;">
            <p class="newsT1 twoLine">{{item[trans('title,titleEn')]}}</p>
            <div class="newsT2">
              <span class="">{{item.createTime}}</span>
            </div>
          </div>
        </div>
      </template>

      <!-- 期刊报告 -->
      <template v-if="module.type == 'report' && module.dataList?.length">
        <div class="newsGrand reports-box" v-for="item in module.dataList" :key="item.id" @click="toDetail(item.id, module.path)">
          <div style="position: relative;cursor: pointer;">
            <img :src="item.img ? baseURL + item.img : ''" alt="" class="newsBg" />
            <p class="industryReportBT">{{item[trans('primaryName,primaryNameEn')]}}</p>
          </div>
          <div style="margin-left: 16px;display: flex;flex-direction: column;justify-content: space-between;">
            <p class="newsT1 twoLine">{{item.title}}</p>
            <div class="newsT2">
              <span><i class="el-icon-document"></i> {{item.page}} {{$t('report.pages')}}</span>
              <span class="industryReportB">{{ $t('common.view') }}</span>
            </div>
          </div>
        </div>
      </template>

      <!-- 商机 -->
      <template v-if="module.type == 'opportunity' && module.dataList?.length">
        <div class="block">
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in module.dataList"
              :key="index"
              
              :timestamp="item.createTime"
            >
              <div class="twoLine" style="cursor: pointer;" @click="toDetail(item.id, module.path)">{{ item.title }}</div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div style="height: 50px; text-align: center; margin-top: 20px" v-if="module.hasMoreBtn">
          <el-button type="primary" plain @click="$router.push(`/${module.path}`)">{{ $t('opportunity.moreR') }}</el-button>
        </div>
      </template>

      <!-- 无图资讯（新闻动态，科技前沿） -->
      <template v-if="module.type == 'technology' && module.dataList?.length">
        <div class="newsGrand1" v-for="item in module.dataList" :key="item.id">
          <p class="newsT1 twoLine" @click="toDetail(item.id, module.path)">{{item[trans('title,titleEn')]}}</p>
          <div class="newsT2">
            <div>
              <span class="label-box" v-for="(label, labelIndex) in (item[trans('label,labelEn')] ? item[trans('label,labelEn')].split(',') : [])"
                :key="labelIndex">{{label}}</span>
            </div>
            <span class="">{{item.createTime}}</span>
          </div>
        </div>
      </template>
      
    </div>

    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
  </div>
</template>
<script>
import mixins from "@/utils/mixins.js";
import LoginTip from "@/components/LoginTip.vue";
import { mapState } from 'vuex'

import {
  getNews,
  getNewsEn,
  getReports
} from "@/api/index";

export default {
  components: { LoginTip },
  mixins: [mixins],
  props: {
    // 'opportunity', 'report', 'news', 'technology'
    modules: {
      type: Array,
      default(){
        return [
          {
            type: 'opportunity',
            title: '最新商机',
            hasMoreBtn: true,
            dataNum: 5,
            primaryTypeId: 3
          },
          {
            type: 'report',
            title: '行业报告',
            hasMoreBtn: true,
            dataNum: 5,
            primaryTypeId: 2
          },
          {
            type: 'news',
            title: '最新资讯',
            hasMoreBtn: true,
            dataNum: 5,
            primaryTypeId: 4
          },
          {
            type: 'technology',
            title: '最新动态',
            hasMoreBtn: true,
            dataNum: 5,
            primaryTypeId: 1
          },
        ]
      }
    }
  },
  computed: {
    ...mapState({
      'access_token': 'access_token', 
      'userInfo': 'user'
    })
  },
  data() {
    return {
      modulesData: [],
      dialogVisible: false
    };
  },
  created(){
    this.modulesData = this.modules

    this.modulesData.map((item, index) => {
      this.getList(index);
    })
  },
  methods: {
    async getList(index){
      let temp = this.modulesData[index];
      
      let fn = temp.type == 'report' ? getReports : temp.type == 'opportunity' ? getNews : (this.$i18n.locale == 'en' ? getNewsEn : getNews);
      let res = await fn({
        primaryTypeid: temp.primaryTypeId,
        secondaryTypeid: temp.type == 'opportunity' ? (this.$i18n.locale == 'en' ? 22 : 15) : '',
        secondaryTypeids: temp.type == 'report' ? (this.$i18n.locale == 'en' ? '6,7' : '4,8') : '',
        pageNum: 1,
        pageSize: this.modulesData[index].dataNum,
        isSort: 2,
        draftStatus: 0,
        articleFlag: 1,
      })
      this.$set(this.modulesData[index], 'dataList', res.rows)
    },
    toDetail(id, type) {
      if('news technology report'.includes(type)){
        this.$router.push(`/${type}/` + id);
      }else{
        if (this.userInfo.userId) {
          this.$router.push(`/${type}/` + id);
        } else {
          this.dialogVisible = true;
        }
      }
    },
    hideDialog() {
      this.dialogVisible = false;
    },
  }
};
</script>
<style lang="scss" scoped>
.module{
  margin-bottom: 26px;
}
.label-box{
  display: inline-block;
  height: 20px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a8b3;
  margin-right: 10px;
  background-color: #f2f7ff;
  border-radius: 10px;
  transition: all .3s;
  cursor: pointer;
  max-width: 8em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover{
    color: #fff;
    background-color: var(--main-color);
  }
}

.rightBox {
  width: 300px;
}

.news {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > a{
    font-size: 14px;
    color: #999;
  }
  & > a:hover{
    color: var(--main-color);
  }
}

.newsTitle {
  position: relative;
  z-index: 0;
}

.newsTitle > p {
  width: 100%;
  height: 12px;
  background: #a6cbff;
  position: absolute;
  right: 0;
  top: 46%;
  border-bottom: none;
  z-index: -1;
}

.newsGrand {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.newsGrand1{
  margin-top: 20px;
}

.newsBg {
  width: 120px;
  height: 80px;
}

.newsT1 {
  height: 2.7em;
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  cursor: pointer;
  &:hover{
    color: var(--main-color);
  }
}

.newsT2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  font-size: 12px;
  line-height: 18px;
  color: #a1a8b3;
}

.industryReport {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.industryReportTitle {
  position: relative;
  z-index: 0;
}

.industryReportColor {
  width: 100%;
  height: 12px;
  background: #a6cbff;
  position: absolute;
  left: 0;
  top: 10px;
  border-bottom: none;
  z-index: -1;
}

.industryReportGrand {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}


.industryReportBT {
  position: absolute;
  top: 6px;
  left: 6px;
  min-width: 36px;
  height: 20px;
  background: rgba(28, 28, 28, 0.9);
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  z-index: 1;
}

.industryReportT1 {
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.industryReportT2 {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  height: 28px;
  overflow: hidden;
}


.industryReportB {
  display: block;
  width: 56px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: #1a79ff;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.el-timeline-item__node {
  position: absolute;
  // background-color: #E4E7ED;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-timeline-item__node
  el-timeline-item__node--normal
  el-timeline-item__node-- {
  position: absolute;
  // background-color: #E4E7ED;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  height: 440px;
  margin-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
}
.block::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.block::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  //background-color: #8c8c8c;
  background-color: rgba(0, 0, 0, 0.25);
}

::v-deep.el-timeline-item {
  // background-image: linear-gradient(to right, red, yellow);
  background-size: 200% 100%;
  transition: background-position 0.5s;
}

::v-deep.el-timeline-item:hover {
  background-image: linear-gradient(to right, white, rgb(248, 248, 248));
  z-index: 1;
  background-position: -100% 0;
}

.el-timeline-item:before {
  transition: 0.3s;
  background: #fafafa;
  z-index: -1;
}

.el-timeline-item:hover:after,
.el-timeline-item:hover:before {
  width: 100%;
}

::v-deep .el-timeline-item__content {
  height: 44px;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 22px;
  padding: 0 0 20px 0;
}

::v-deep .el-timeline-item__content:hover {
  color: var(--main-color);
}

::v-deep .el-timeline-item__timestamp.is-bottom {
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  color: #a1a8b3;
}

::v-deep .el-timeline-item__tail {
  top: 10px;
  left: 7px;
  border: 1px dashed #f2f7ff;
}

::v-deep .el-timeline-item__node {
  border: 1px solid skyblue;
  background: white;
  top: 4px;
  left: 2px;
}

.el-button--primary.is-plain {
  display: inline-block;
  width: 170px;
  // height: 40px;
  font-size: 14px;
  color: #a1a8b3;
  // line-height: 40px;
  border: 1px solid #a1a8b3;
  border-radius: 2px;
  transition: all 0.3s;
  cursor: pointer;
  background: #fff;
  &:hover{
    background-color: var(--main-color);
    color: #fff;
    border-color: var(--main-color);
  }
}

.el-button--mini{
  color: #a1a8b3;
  background-color: #f2f7ff;
}

.el-button--mini:hover {
  color: #fff;
  background: #1a79ff;
}

.reports-box{
  .newsBg{
    width: 74px;
    height: 100px;
  }
}
</style>


